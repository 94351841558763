.employeeOld {
    position: relative;
    padding-top:40px;
    background-color: var(--color-light-bg);
    margin-bottom:100px;
    > header {
        display:flex;
        align-content: center;
        padding: 0 var(--outside-space);
        color:var(--color-white);
        min-height:70px;
        position: relative;

        .actions {
            display:flex;
            align-items: center;
            gap:24px;
            color:var(--color-white);
            .avatar {border-color: var(--color-white)}
            .icon-18, .icon-24 {color: var(--color-white)}
        }
    }
    > main {
        padding: 20px 0;
        position: relative;

        section {
            padding:  var(--outside-space);
            
            h2 {
                margin-bottom:24px;
            }
        }
    }

    &:before {
        content: "";
        background-image: url("../images/background-swoop.svg");
        background-repeat: no-repeat;
        background-size:100% 100%;
        width:100%;
        min-height: 45vh;
        position: absolute;
        left:0;
        right:0;
        top:0;
        z-index:0;
    }
    

    
    
    section.dark-gradient-bg {
        padding-top:40px;
        padding-bottom:40px;
        margin-bottom:40px;
        
    }
    
    
    
}
.course-list {
    list-style: none;margin:0 0 40px 0;padding:0;
    .card {
        h3 {
            font-weight: 700;
            margin-bottom: 16px;
        }
        .course-details {
            display: block;
            color: var(--color-text-muted);
            margin-bottom:0;
        }
    }
}
.pageWrapper.profile {
    .user-info {
        background-color: var(--color-medium-blue);
        color:var(--color-white);
        border-radius:8px;
        padding:40px 24px 24px;
        text-align:center;
        border:0;
        margin-bottom:var(--column-gap);
        

        .avatar {
            width:180px;
            height:180px;
            border:4px solid var(--color-white);
            overflow:hidden;
            border-radius:50%;
            margin: 24px auto;
            img {
                width: 100%;
                max-width:100%;
                transform:scale(1.05);
            }
        }
        .user-name {
            font-size:2em;
            
        }
        .user-location {
            line-height:24px;
            color: var(--color-bright-teal);
            margin:12px 0 0 0;
            .icon {
                vertical-align: top;
                svg {
                    display: inline-block;
                }
            }
        }
        .user-occupation {
            margin:20px 0 0 0;
        }
    }
    .profile-progress {
        text-align:center;
        max-width:600px;
        margin-left:auto;
        margin-right:auto;
        .progress-bar-container {
            margin: 24px 0;
        }

    }
    .overflow-scroll {
        overflow-x:auto;
        display:flex;
        flex-direction:row;
        gap:var(--column-gap);
        .card {
            max-width:321px;
            flex:none;
            height:auto;}
    }
    .employee-skills {
        h3 {
            font-weight:700;
            margin-bottom:16px;
        }
        .card-column {
            display:flex;
            flex-direction:column;
            gap: var(--column-gap);
            flex:none;
        }
        .card {
            color: var(--color-black);
            .chip-label {
                border-color: var(--color-black);
                margin: 0px;
                display: inline-block;
            }
        }
        .card-row {
            display:flex;
            flex-direction: row;
            // align-items: flex-start;
            gap:var(--column-gap);
          
        }
        .overflow-scroll > .card-row {
            flex:none;
            flex-wrap:wrap;

            @media screen and (max-width: 1024px) {
                width:680px;
            }
        }
    }

    .badge-list {
        list-style-type:none;
        margin:0;
        padding:0;

        li {
            padding: 8px 0;
            &:first-of-type {
                padding-top:0;
            }
        }
        .badge-item {
            display:flex;
            align-items: center;
            gap:20px;
        }
        .badge {
            max-width:68px;
            img {
                width:100%;
                max-width:100%;
            }
        }
    }
    .experience-entry {
        margin-bottom:32px;
        p {
            margin-bottom: 1rem;
        }
        .company-logo {
            display: flex;
            align-items: center;
            gap:10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            margin-bottom:16px;
            .company-icon {
                width:40px;
                height:40px;
                overflow:hidden;
            }
            .company-name {
                display: block;
            }
        }
        .experience-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 32px;
            /* or 100% */

            letter-spacing: 0.4px;
            margin-bottom:8px;
        }
        .experience-duration {
            font-weight: 500;
            font-size: 22px;
            line-height: 24px;
            color: var(--color-text-light);
            margin-top:0.75rem;
            margin-bottom:1rem;
        }
        
    }
    @media (max-width:1024px) {
        .user-info {
            
            max-width:600px;
            margin-left:auto;
            margin-right:auto;
        }
    }
    @media (min-width:1025px) {
        
        section.intro {
            padding:0;
            .user-info {
                border-radius:0;
            }
        }
        .profile-progress {
            max-width:calc(100% - 160px);
        }
        .employee-skills {
            .overflow-scroll {
                overflow: hidden;
                flex-wrap:wrap;
                .card {
                    flex:1 1 320px;
                }
            }
            .card-column {
                flex-direction: row;
            }
        }
       
    }
}

@media (max-width:1024px) {
    .pageWrapper main section h2 {
        font-size:28px;
        line-height:28px;
        margin-bottom:16px;
    }
    .pageWrapper main section.employee-skills h2 {
        margin-bottom:24px;
    }
    .about-me {
        padding-bottom:40px !important;
        p {
            font-size:20px;
            line-height:24px;
            &:last-of-type {margin-bottom:0;}
        }
    }
    p {
        font-size:20px;
        line-height:24px;
    }
    .pageWrapper.profile .employee-skills .card-column {
        gap: 16px;
    }
    .pageWrapper.profile .overflow-scroll {
        gap:16px;
    }
}