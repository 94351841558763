footer {
    padding: 46px 24px 136px 24px;

    ul.footer-links {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        gap: 24px;
        justify-content: center;
        flex-wrap: wrap;

        li {
            margin: 0;
            padding: 0;

            a {
                color: var(--color-text-light);
                text-decoration: none;
                white-space: nowrap;
                font-size: 14px;
                line-height: 1.143;

                &:hover {
                    text-decoration: underline;
                    color: var(--color-text-default);
                }
           } 
        }
    }
}
