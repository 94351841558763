// main {
//     // background: #212e47;
// }

.issuer-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 48px;
    //background: white;
}

.pageWrapper .pillar-page .badges {
    padding-top: 32px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.left-container {
    display: flex;
    align-items: center;
}

.right-container {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width:100%;
    // align-items: center;
    justify-content: center;
    margin-left: 16px;
    margin-right: 16px;
}

.ac-heading--badge-name-hero {
    font-weight: 700;
    font-style: normal;
    font-size: 32px;
    line-height: 1.25;
}

.ac-heading--badge-name-hero-subtitle {
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    line-height: 1.25;
}

.bio {
    margin-top: 16px;
    //font-family: "TT Commons", sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.24px;
    width: 100% !Important;
}

.link {
    color: white;
}

.links {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.link:not(:first-child) {
    margin-left: 16px;
}

.badge-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // margin-left: 80px;
    // margin-right: 80px;
    margin: 0 auto;
    max-width: 1200px;
}

.badges-section {
    padding-bottom: 36px !important;
    background: #212e47 !important;
}

.badge-header {
    display: flex;
    width: 100%;
    color: white;
    padding-bottom: 6px;
}

.badges {
    background: #212e47;
}

.badge-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    gap: 12px;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom:16px !important;
    padding-bottom: 16px !important;
}

.badges-header-wrapper {
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 0;
    justify-content: center;
}

.badge-container li {
    display: flex;
    border-radius: .5vh;
}

.badge-body {
    display: flex;
    align-items: center;
    background: white;
    width: 328px;
    height: 198px;
    padding-left: 45px;
    padding-right: 30px;
}

.badge-name {
    margin-left: 10px;
    font-family: "TT Commons", sans-serif;
    width: 122px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.24px
}

.slug-logo {
    width: 340px;
    //background-color: white;
}

.mobile-body {
    background: white;
}

/**************
Credentials Page
**************/
badge-image--center {
    display: flex;
    flex-direction: column;
    align-items: center
}
.badge-labels--center {
    display: flex;
    flex-direction: column;
    max-width: 531px;
    margin: 0 auto;
}
/*********************/
.issuer-detail {
    @media (max-width:1024px) {
        position: relative;
            background: linear-gradient(0deg, #05112A 80%, rgba(0,0,0,0) 100%);
            // background: var(--color-gradient-dark);
            text-align:center;
            margin-bottom:0;
            color: var(--color-white);

        .breadcrumb-container {
            display: block;
            margin-bottom:24px;
                text-align:left;
            
                a, button {color: var(--color-white);}
        
        }
        .issuer-wrapper {
            flex-direction: column;
            align-items: center;

            h1, p, .bio {
                color: var(--color-white);
            }
        }
        .transparent-img {
            background-color: white;
        }

    }
}