.employer {
    .intro {
        &::before {
            content: "";
            display: block;
            background: var(--color-gradient-dark-alt);
            position: absolute;
            width: 100%;
            height: 228px;
            top: 0;
            left: 0;
            z-index: 0;

            @media (max-width: 1024px){
                display: none;
            }
        }

        h1, h2 {
            position: relative;
            z-index: 1;

        }
        h1 {
            margin-bottom: 32px;
            color: var(--color-white);
        }
        h2 {
            color: var(--color-white);
            .chip-highlight {margin-left:8px;vertical-align: middle;min-height:24px;line-height:16px;padding:5px 12px 4px 12px;}
        }
        .modal-body h2,
        .light-bg h2 {
            color: var(--color-black);
        }
        .hero {
            margin-top:56px;

            + .card-list {margin-top:16px;}
        }

        &.tall {
            &:before {
                height: 530px;
            }

            .card {
                &.c1asssummary {
                    min-height: 300px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    h2, p {
                        max-width:50%;
                        z-index: 1;
                    }
                }
            }
        }
    }
    &.employees {
        .tab-content {
            // padding: 0 18px;
        }
    }

    .pillar-page {
        .intro::before {content:none}
    }
}
h2 {
    .right-shift {
        font-size:1.125rem;
                float: right;
        font-weight: normal;
    }
}