.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    &.open {
        display: block;
    }
    &.hidden {
        display: none;
    }
    .modal-backdrop {
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,.5);
        z-index: 0;
    }
    .modal-window {
        position: relative;
        width: 100%;
        padding: 20px 50px;
        background: var(--color-white);
        z-index: 2;
        top: -75vh;
        left: calc(50vw - 165px);
        box-sizing: border-box;
        border-radius: 5px;
        max-width: 500px;
        transition: top 0.4s;
        box-shadow: 1px 1px 8px 2px rgba(0,0,0,.25);
        &.in {
            top: 25vh;
        }
        .modal-close button {
            position: absolute;
            right: 20px;
            top: 10px;
            font-size: 20px;
            cursor: pointer;
        }
        .buttonBar {
            background: var(--color-light-grey);
            margin: 30px -50px -20px -50px;
            padding: 20px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            @media (max-width: 1024px){
                position: relative;
                // height: 100vh;
            }
        }
        @media (max-width:1024px) {
            left: calc(50vw - 270px);
            max-height: 100vh;
            min-width: 0;
            width: calc(100vw - 40px);
            margin:20px;
            &.in {
                top: 5vh;
            }
        }

        @media (max-width: 540px){
            left: 0;
            max-height: 100vh;
            min-width: 0;
            width: calc(100vw - 40px);
            margin:20px;
            &.in {
                top: 5vh;
            }
        }
    }
    .recommend.innerContent {
        display: grid;
        grid-template-columns: repeat(2,50%);

        img {
            width: 90%;
            height: auto;
            float: left;
            margin-right: 20px;
        }
    }
}
.modal-window-open:not(.modal-overlay) {
    overflow: hidden;
    .pageWrapper > header {
        z-index: 1;
    }
   .pageWrapper header, .pageWrapper main, .pageWrapper section, .pageWrapper:before, nav {
        //filter: blur(2px);
    }
}

.modal.sheet {
    .modal-window {
        height: calc(100vh - 44px);
        border-radius: 5px 5px 0 0;
        padding-top: 50px;
        background: var(--color-light-bg);
        top: 200vh;
        overflow: auto;
        padding: 50px 0px;
        max-width:100%;
        // left:0;
        @media (max-width: 1024px){
            left: 0;
            max-height: 100vh;
            min-width: 0;
            width: 100vw;
            margin:0;
            &.in {
                top: 44px;
            }
        }
    }
    .modal-window.in {
        top: 44px;
        .modal-close {
            top: 45px;
            left: 16px;
        }
    }
    .modal-close {
        position: fixed;
        top: calc(200vh + 45px);
        transition: top .4s;
        width: 100%;
        background: linear-gradient(var(--color-light-bg) 40%, rgba(255,255,255,0));
        height: 50px;
        margin: -1px -16px;
        border-radius: 5px 5px 0 0;
        z-index: 10;
    }
    .modal-close button {
        position: absolute;
        width: 90px;
        background: var(--color-text-light);
        height: 5px;
        border-radius: 10px;
        right: 50%;
        margin-right: -45px;
        overflow: hidden;
    }

    .filters {
        padding:16px;
        h2 {
            padding-bottom:0.5em;
            margin-bottom:1em;
            border-bottom:1px solid var(--color-border);
        }
        .filter-group {
            margin-bottom:32px;
        }

        fieldset {
            border:0;
            margin:0;
            padding:0;

            legend {
                font-size: 20px;
                line-height:28px;
                font-weight:bold;
                margin-bottom:16px;
            }
        }
    }
    .button-row {text-align: center;}
}

.modal.overlay {
    .modal-window {
        height: 100%;
        border-radius: 0;
        background: var(--color-light-bg);
        top: unset;
        left: unset;
        right: -500px;
        overflow: auto;
        padding: 0;
        margin: 0;
        width: 100vw;
        transition: right .4s;
        > .breadcrumb-container {
            position: fixed;
            color: var(--color-white);
            z-index: 20;
            top: 40px;
            left: 516px;
            transition: left .4s;
            button {
                cursor: pointer;
                color: var(--color-white);
            }
        }
        .pageWrapper .breadcrumb-container {
            opacity: 0;
        }
        @media (max-width: 1024px){
            // left: 0;
            // max-height: 100vh;
            // min-width: 0;
            // width: 100vw;
            max-width:100%;
            // margin:0;
        }
    }
    .modal-window.in {
        right: 0;
        > .breadcrumb-container {
            left: 16px;
        }
        .modal-close {
            top: 45px;
            left: 16px;
        }
    }
}