.work-style-insights {
    background-color: var(--color-medium-blue);
    color:var(--color-white);
    margin-bottom:40px;
    
    .insights-header {
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom:32px;
        h2 {margin-bottom: 0;}
        a, button {color:var(--color-white);}
    }
    .insights-body {
        display:flex;
        flex-direction: column;
        gap:24px;
    }
    p {
        text-align:center;
    }
    button {
        margin: 0 auto;
    }
}
.insight-block {
    padding: 24px 32px;
    background: rgba(2, 9, 23, 0.4);
    border-radius: 8px;
    display:flex;
    align-items: center;
    gap:24px;
    color:var(--color-white);
    .bar-group, .insight-title {
        flex:1;
    }
    .bar-group {
        display:flex;
        flex-direction: column;
        gap:6px;
        width:100%;
    }
    .bar-container {
        width:100%;
        position: relative;
    }
    
    .bar {
        height:16px;
        border-radius:4px;
    }

    &.work {
        .bar {
            background-color: #F05B95;
        }
    }
    &.relate {
        .bar {
            background-color: #F8BB69;
        }
    }
    &.emotions {
        .bar {
            background-color: #0CC0AD;
        }
    }
    &.thinking {
        .bar {
            background-color: #31B9E1;
        }
    }
}

.pageWrapper.insights {
    .employee-nav {display: none;}
    .intro {
        padding-top:60px;
        color:var(--color-white);
        .back-btn {
            margin-bottom:20px;
        }
    }
    .tab-list-container {
        // overflow-x: auto;
        .tab-action {color:var(--color-white)}
    }
    .work-insights.card {
        background:var(--color-medium-blue);
        border-color:var(--color-medium-blue);
        color:var(--color-white);
        margin: 20px;
        height:auto;
        // padding: 40px;
        .section-header {
            border-bottom: 1px solid #4F5561;
            margin-bottom:20px;
            padding-bottom:20px;
            h2 {margin:0;}
        }
    }
    .insights-group {
        margin-bottom:80px;

        &.work {
            --bar-color: #F05B95;
        }
        &.relate {
            --bar-color: #F8BB69;
        }
        &.emotions {
            --bar-color:#0CC0AD;
        }
        &.thinking {
            --bar-color: #31B9E1;
        }
        .insights-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width:100%;
            background:transparent;
            border:none;
            color:var(--color-white);
            text-align:left;
            padding:0;
            font-weight: 700;

            .icon {
                display: inline-block;
                margin-right:8px;
                transition:transform 0.3s ease;
            }
            &[aria-expanded=true] {
               .icon{ transform:rotate(180deg);}

                + .insights-body {
                    .bar-description {
                        display: block;
                    }
                    .insights-more-details {
                        display: block;
                    }
                }
            }
        }
        .insights-definition {
            margin-top:0.5em;
        }
        .insights-bar-container {
            padding-top:65px;
            
            // padding-bottom:40px;
            font-size:1em;
            line-height:1.5;
            .bar-container {
                position: relative;
                height:32px;
                width:100%;
                border:1px solid var(--bar-color);
                border-radius:4px;
                .bar {
                    height:30px;
                    background-color: var(--bar-color);
                    border-top-left-radius: 4px;
                    border-bottom-left-radius:4px;
                    position: absolute;
                    left:0;
                    span {
                        position:absolute;
                        right:0;
                        bottom:34px;
                        text-align:center;
                        transform: translateX(50%);
                        line-height:1.1;
                        strong {display: block;}
                        &:after {
                            content:'';
                            display: block;
                            width: 0; 
                            height: 0;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                            border-top: 8px solid var(--bar-color);
                            // background-color:var(--bar-color);
                            z-index: 1;
                            margin: 8px auto;
                        }
                    }
                    
                }
                .fifty {
                    position: absolute;
                    left:50%;
                    font-weight:600;
                    // line-height: 18px;

                    > span {
                        display: flex;
                        align-items: center;
                        gap:8px;
                        margin-top:9px;
                        transform:translateX(-50%);
                        vertical-align: middle;
                    }
                    
                    .icon {
                        display:inline-block;
                        color:var(--color-bright-teal);
                        border-radius:50%;
                        width:18px;
                        height:18px;
                        border:1px solid var(--color-bright-teal);
                        
                    }
                    &:before {
                        content:'';
                        display: block;
                        height:30px;
                        width:2px;
                        background-color:var(--color-white);
                        z-index: 1;
                        
                    }
                    
                }
            }
           
            .labels {
                display:flex;
                justify-content: space-between;
                align-items: flex-start;
                gap:10%;
                padding-top:2.5em;
                strong {display:block;font-weight:normal;margin-bottom:4px;}
                .maximum-label {text-align:right;}

                @media (min-width:1025px) {
                    padding-top:0.5em;
                    gap:24%;
                    .minimum-label, .maximum-label {flex: 0 1 38%;}
                }
            }
        }
        .insights-body {
            padding-left:32px;

            .bar-description {
                display: none;
            }
            .insights-more-details {
                display: none;
                // border-bottom:1px solid white;
                padding-top:60px;
                padding-bottom:60px;
            }
            h4 {
                font-weight: 700;
                font-size: 24px;
                line-height: 28px;
                margin-bottom:0;
                margin-top:0;
            }
            p {
                font-size: 1.125em;
            }
            .insights-meaning {
                margin-bottom:60px;
            }
        }

        &.expanded {
            border-bottom: 1px solid #4F5561;
            .minimum-label, .maximum-label {
                strong {font-weight:700;}
            }
        }
    }

    .link-btn {
        color:var(--color-bright-teal);
    }
    

    @media (max-width:1024px) {
        .intro {background:transparent;}
        .insights-bar-container {font-size:90%;}
    }
}
.insights-accordion {
    border-bottom: 1px solid #4F5561;

    .tab-action {
        width:100%;
        text-align:left;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.4px;
        color:var(--color-white);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap:12px;
        // margin: 8px 20px;
        margin:20px 0;
        padding: 8px 0;
        text-decoration: none;
        background: none;
        border: 0;
        // border-bottom: 3px solid transparent;

        .icon {
            display: block;
            background-color: var(--color--accent-01-medium);
            border-radius:50%;
            padding:3px;
            width:30px;
            height:30px;
            .vert {
                transition:opacity 0.3s ease;
            }
            .horiz {
                transition: transform 0.3s ease;
                transform-origin: center;
            }
        }
        &[aria-expanded=true] {
            font-weight:700;

            .icon {

                .vert {
                    opacity:0;
                }
                .horiz {
                    transform:rotate(90deg)
                }
            }
            // border-bottom: 3px solid ;
        }
    }


}

.work-style-quiz {
    position: relative;
    background-color: var(--color-light-bg);
    min-height:100vh;
    &:before {
        content: "";
        background: transparent;
        background-image: url("../images/background-swoop-small.svg");
        background-repeat: no-repeat;
        background-size:cover;
        background-position: top;
        width:100%;
        min-height: 170px;
        position: absolute;
        left:0;
        right:0;
        top:0;
        transform: none;
        z-index:0;
    }

    header {
        position: relative;
        z-index: 1;
        background:transparent;
        padding: 12px 21px;

        a {
            color:var(--color-bright-teal);
        }
    }
    main {
        position: relative;
        padding: 100px 20px 40px 20px;
        max-width:900px;
        margin:auto;
        z-index: 1;

        section h1 {
            margin-bottom:60px;
            text-align:center;

        }
        p {
            margin-bottom:1em;
        }
    }
    .assessment-header {
        text-align:center;
        padding:20px 0;
        min-height: 60px;
    }
    fieldset {
        border:0;
        margin:0;
        padding:0;

        legend {text-align:center;}
    }
    .radio-options {
        max-width:300px;
        margin:32px auto;

        .radio-option {
            position:relative;
            background-color: var(--color-light-200);
            padding: 12px 40px;
            margin-top: 16px;
            border-radius:8px;

            input[type=radio] {
                opacity:0;
                position:absolute;
                ~ span {
                    border: 2px solid #707D89;
                    background: var(--color-white);
                    border-radius: 50%;
                    box-sizing: content-box;
                    color: var(--color-teal);
                    display: block;
                    height: 18px;
                    width: 18px;
                    // padding:2px;
                    pointer-events: none;
                    position: absolute;
                    top: 12px;
                    left: 40px;

                    svg {
                        position:absolute;
                        left:0;
                        top:0;
                        fill:currentColor;
                        opacity:0;
                    }
                }
                &:focus ~ span {
                    outline: 0;
                    box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4; 
                }
                &:checked ~ span{
                    border-color:var(--color-teal);
                }
                &:checked ~span svg {
                    opacity:1;
                }
            }
        }
        label {
            font-size: 24px;
            line-height: 28px;
            padding-left:40px;
            cursor: pointer;
        }
    }
    .button-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap:wrap;
        gap:12px;
        margin-top:80px;
        // margin-bottom:80px;
        button {white-space: nowrap;}
    }
}