.pageWrapper.search-page {
    padding-bottom:40px;
    min-height:100vh;
    &:before {content:none}
    > main {
        padding-top:0;
    }

    .breadcrumb-container {
        display: block;
        margin-bottom:24px;
            text-align:left;
        
            a, button {color: var(--color-white);}
    
    }
    .intro.dark-bg {
        position: relative;
        // background: linear-gradient(0deg, #05112A 80%, rgba(0,0,0,0) 100%);
        // text-align:center;
        margin-bottom:0;
        padding-top:40px;
        min-height:300px;
        z-index: 11;
        

        // background: var(--color-gradient-dark);

        .scroll-hide {
            opacity:1;
            max-height:100%;
            height: 130px;
            transition:all 0.2s ease;
            overflow: hidden;
        }

        // @media (min-width:420px) and (max-width:1024px) {
        //     min-height:300px;
        //     + .search-contents {
        //         padding-top:360px;
        //         .tab-list-container {
        //             // position: sticky;
        //             top:300px;
        //         }
        //     }
        // }
        

        @media (max-width:1024px) {
                position: fixed;
                width:100%;
                background: transparent;
                background-image: url("../images/background-swoop.svg");
                background-repeat: no-repeat;
                background-size:100% 140%;
                overflow:hidden;
                transition: min-height ease-in-out 0.3s;
                + .search-contents {
                    padding-top:360px;
                    transition: padding ease-in-out 0.3s;
                    .tab-list-container {
                        position: fixed;
                        width:100%;
                        top:300px;
                        z-index: 11;
                        transition: top ease 0.25s;
                    }
                }

                &.sticky {
                    min-height:183px;
                    // position: sticky;
                    // top:0;
                    // z-index: 11;
                    .scroll-hide {
                        opacity: 0;
                        max-height:0;
                        height: 0;

                    }
                    // .search-actions {
                    //     // position:sticky;
                    //     // top:163px;
                    //     // z-index: 111;
                    //     background-color: var(--color-dark-blue);
                    // }
                    +.search-contents {
                        padding-top:200px;
                    }
                    +.search-contents .tab-list-container {
                    //     position:sticky;
                        top:183px;
                    //     z-index: 111;
                    //     background-color: var(--color-dark-blue);
                    }
                }
                
        }
        @media (max-width:419px) {
            min-height:340px;
            .scroll-hide {
                height: 155px;
            }
            + .search-contents {
                padding-top:390px;
                .tab-list-container {
                    // position: sticky;
                    top:340px;
                }
            }
        }
       

    }
    .search-actions {
        display:flex;
        align-items: center;
        gap:12px;
        margin-top:24px;

        .search-input {
            flex:1;
            .gr-input {
                font-size:1.125rem;
                min-height:48px;
                &::placeholder {
                    font-size:1.25rem;
                }
            }
        }
        .filter .icon-btn {
            background-color:var(--color-white);
            color:var(--color-black);
            border: 1px solid #DDE3EE;
        }
    }
    .search-contents {
        padding:0;
    }
    .tab-list-container {
    
            background-color: var(--color-dark-blue);
        
        // margin-left: calc(-1 * var(--outside-space));
        // margin-right: calc(-1 * var(--outside-space));
        .tab-list {
            margin:0;
        }
        .tab-item {
            width:50%;
            .tab-action {
                width:100%;
                color:var(--color-white);
                margin-left:0;
                margin-right:0;
            }
        }
    }
    .tab-content {
        background-color: var(--color-light-bg);
        min-height:300px;
        section {
            padding-top:24px;
        }
    }
    .badge-group-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom:16px;
        h3 {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            
            .tagline {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: var(--color-text-light);
                display:block;
                margin-bottom:4px;
            }
            
        }
    }
    .card.search-result {
        
        position: relative;
        margin-bottom:16px;

        &:last-of-type {
            margin-bottom:0;
        }

        .learning-link {
            display:flex;
            align-items: center;
            gap:24px;
        }

        .bookmark {
            position: absolute;
            right:16px;
            top:16px;
            color:var(--color-text-light);
                z-index: 2;
            &[aria-pressed=true] {
                color:var(--color-plum);
            }
            &:hover {
                color:var(--color-plum);
            }
        }

        .program-img {
            img {
                max-width:70px;
            }
        }
        .badge-info {
            flex:1;
        }
        .card-title {
            padding-right:40px;
            text-overflow: ellipsis;
            font-size:16px;
        }
        .card-text {
            color:var(--color-text-light);
            font-weight:700;
            margin:0;
            font-size:14px;
        }
        .card-features {
            color:var(--color-text-light);
            margin:0;
            font-size:14px;
        }
    }

    @media (min-width:1025px) {
        &:before {
            content:'';
        }
        .tab-list-container {
            background-color: var(--color-medium-blue);
        }
    }
}