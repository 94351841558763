.accordion-container {
    background-color:#FFFFFF;
    border: 1px solid var(--color-border);
    border-radius: calc(0.5 *var( --card-corner-default));
    margin-bottom: 1em;

    .accordion-body {
        display:none;
        padding: 24px;
        border-top:1px solid var(--color-border);

        ul {
            margin:0;
            padding: 0px;
            list-style: none;
            li {
                font-size:1rem;
                padding: 4px 0;
            }
        }
    }
    .accordion-btn[aria-expanded=true] {
        + .accordion-body {display:block;}
        .icon-24 {
            transform:rotate(180deg);
        }
    }
}
.accordion-btn {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    gap:10px;
    width:100%;
    padding: 10px 24px;
    font-size:22px;
    line-height:40px;
    font-weight:500;
    background:transparent;
    border:none;
    text-align: left;
    color: var(--color-black);

    &.course-accordion {
        line-height:32px;
    }
    .icon-24 {
        width:40px;
        height:40px;
        display:block;
        border-radius:50%;
        transition:transform 0.3s ease;
        text-align: center;
    }
    &:hover {
        .icon-24 {
            background: var(--color-light-200);
        }
    }
}

// fake accordion!
.update-container {
    .accordion {
        font-size: 22px;
        line-height: 24px;
        padding: 8px 24px;
        border: 1px solid var(--color-border);
        border-radius: 8px;
        margin: 10px 0;
        .icon-18 {
            float: right;
            position: relative;
            top: 7px;
        }
    }
    .right.label {
        text-align: right;
        color: var(--color-text-muted);
        margin-bottom:8px;
    }
}
