.analytics {
    .dark-bg {
        position: relative;
        background-color:transparent !important;
        z-index: 1;
        &:before {
            content:'';
            width:100%;
            height:50%;
            background: linear-gradient(83.93deg, #05112A 51.9%, #1A2538 80.98%, #2A3B5B 107.95%);
            position: absolute;
            left:0;
            top:0;
            right:0;
            z-index: -1;
        }
        .card-list {
            margin-top: 32px;
        }
        .card.overview {
            .button-link {display: none;}
            .trending-line {margin-top:20px;}
        }   
        .button-link {
            text-decoration: none;
            font-weight:700;
            letter-spacing:0.5px;
        }
    }
   
    .analytics-actions {
        display:flex;
        flex-direction:row;
        align-items: center;
        justify-content: space-between;
        width:100%;

    
        .form-input {
            display:flex;
            align-items: center;
            label {
                margin-right:10px;
                line-height:24px;
            }
        }
    }
    .infographic-row {
        
    }
    .section-body {
        .card-row {
            display: flex;
            > .card, > .card-column {
                flex:1;
            }
        }
        .card .card-row {flex-wrap:wrap;overflow:hidden;}
    }

    .card-row {
        display:flex;
        flex-direction: column;
        gap:var(--column-gap);
        
    }
    .card-column {
        display:flex;
        flex-direction: column;
        gap:var(--column-gap);
    }

    

    @media (min-width:1025px) {
        .infographic-row {
            display:grid;
            grid-template-columns: 25% 75%;
            // grid-gap:16px;
        }       
        .program-health .infographic-row{
                display: block;
        }
        .card-column {
            // flex-direction: row;
            // align-items: flex-start;
        }
    }
    @media (min-width:1400px) {
        .program-health .infographic-row{
            display:grid;
            grid-template-columns: 33% 67%;
        }
        .section-body .graphs-row {   
            display:grid;
            grid-template-columns: 50% 50%;
            
        }
    }

}

.trending-up {
    color:var(--color-plum);
    svg {
        fill:currentColor;
    }
}