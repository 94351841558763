@import "fonts";
@import "fontawesome";
@import "typography";
@import "variables";
@import "icons";
@import "pagewrapper";
@import "buttons";
@import "header";
@import "cards";
@import "navigation";
@import "chips";
@import "avatarList";
@import "activityList"; 
@import "progress";
@import "tabs";
@import "inputs";
@import "search";
@import "tables";
@import "employee";
@import "employer"; 
@import "login";
@import "accountmenu";
@import "pillar";
@import "breadcrumbs";
@import "infographics";
@import "footer";
@import "empoyee-navigation";
@import "profile";
@import "channels";
@import "modal";
@import "video-player";
@import "analytics";
@import "hero";
@import "accordion";
@import "pathways";
@import "issuerDetails";
@import "notifications";
@import "skeleton";
@import "searchPage";
@import "toast";
@import "coursedetails";
@import "checkout";
@import "insights";
@import "careers";
@import "avatar";
@import "charts";
@import "surveys";
@import "detailPage";

body {
    color: var(--color-black);
    font-family: "TT Commons", sans-serif;
    margin: 0;
    overflow-x: hidden;
}
button {
    font-family: "TT Commons", sans-serif;
    font-size:100%; 
}
* {
    box-sizing: border-box; 
}

.dark-gradient-bg {
    background: linear-gradient(222.08deg, rgba(109, 1, 118, 0.8) 0.25%, rgba(5, 17, 42, 0) 34.61%), #05112A !important;
    color: var(--color-white) !important;
    .card {
        color: var(--color-black) !important;
    }
    a:not(.chip-btn):not(.button-primary) {color: var(--color-bright-teal);}
}
.dark-bg {
    background-color: var(--color-medium-blue);
    color:var(--color-white);
    .card {color: var(--color-black);}
    // a {color: var(--color-bright-teal);}
    a:not(.chip-btn):not(.button-primary), .button-link {color: var(--color-bright-teal);}
    .card {
        a:not(.chip-btn), .button-link {color: var(--color-teal);}
    }
}
.teal-gradient-bg {
    background: var(--color-gradient-dark-teal);
    color: var(--color-white) !important;
    .card {
        color: var(--color-black) !important;
    }
    a:not(.chip-btn):not(.button-primary), .button-link {color: var(--color-bright-teal);}
    .card {
        a:not(.chip-btn), .button-link {color: var(--color-teal);}
    }
}
.mobile-only {
    display: none !important;
    @media (max-width:1024px){
        display: block !important;
        &.icon-btn {
            display:flex !important;
        }
    }


}
.desktop-only {
    display: block !important;
    &.icon-btn {
        display:flex !important;
    }
    @media (max-width:1024px){
        display: none !important;
        &.icon-btn {
            display:none !important;
        }
    }
}

.loading {
    width: 100vw;
    height: 100vh;
    background: #05112a;
    overflow: hidden;
    .innerLoading {
        display: block;
        padding-top: calc(50vh - 50px);
        left: calc(50vw - 50px);
        position: relative;
        img {
            width: 100px;
            height: auto;
        }
    }
}
.tooltip-content {
    font-size:1rem;
    padding:10px;
    strong, span {display:block;}
    strong {font-size: 1.125em;margin-bottom:0.5em;}
}
.pq-tooltip.tippy-box {
    background-color: var(--color-dark-blue);
}
.pq-tooltip.tippy-box .tippy-arrow {
    color: var(--color-dark-blue);
}