.chart-container {

    .chart-header {
        display:flex;
        flex-direction:row;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom:10px;

        .chart-label {
            font-size:18px;
            line-height:24px;
            font-weight:700;
            span {
                display:block;
                margin-bottom:8px;
            }
            .chart-now {
                font-weight: 400;
                font-size: 32px;
            }
        }
    }
    .chart-time {
        display: none;

        fieldset {
            border:0;
            margin:0;
            padding:0;
        }

        .radio-row {
            display:flex;

            .timeframe {
                position: relative;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: var(--color-text-muted);
                margin-left:10px;

                input {
                    position: absolute;
                    left:0;
                    top:0;

                    &:checked {

                        + span {
                            color:var(--color-black);
                            font-weight:700;
                        }
                    }
                }
            }

            input {opacity:0;}
        }
    }
    .recharts-area-dots {
        circle {
            display: none;
            &:last-child {
                display: block;
            }
        }
    }
    @media (min-width: 1025px) {
        .chart-time {
            display: block;
        }

    }
}

.chart-switcher {
    display:flex;
    flex-direction: column;

    .tab-list {
        margin-bottom:0;
        .tab-item {
            
            display:block;
            font-weight:400;
            button {
                width:100%;
                font-size:18px;
                text-align:left;
                padding: 16px;
                border-radius: 16px;
                margin:0;
                color: var(--color-text-muted);

                strong {
                    color:var(--color-black);
                }
                &[aria-selected=true] {
                    background: #FEFEFE;
                    font-weight:400;
                }
            }
            span {
                display: block;
                
                &.number {
                    font-size: 32px;
                    margin-top:16px;
                    color:var(--color-black);
                }
                svg {
                    vertical-align: middle;
                    fill:var(--color-plum);
                }
            }
        }
    }
    
    @media (max-width:1024px) {
        .chart-container {
            background: var(--color-white);
            border: 1px solid var(--color-border);
            border-radius: var(--card-corner-default);
            padding:16px;
            margin-bottom:16px;
        }
    }
    @media (min-width:1025px) {
        flex-direction: row;
        gap:80px;
        .tab-content {
            flex:0 1 66%;
            overflow:hidden;
        }
        .tab-list-container {
            flex:1 0 33%;
            min-width:200px;
        }
    }
}

.progress-bar-container {
    margin-bottom:20px;
    .progress-bar-labels {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap:10px;
        font-size:16px;
        line-height:20px;
        margin-bottom:0.5em;
        .bar-value {
            color: var(--color-text-muted);
            text-align:right;
        }
    }
    .bar-container {
        position: relative;
        background: var(--color-light-grey);
        height:4px;
        width: 100%;
        // border-radius:4px;

        .bar {
            height:4px;
            // border-radius:4px;
            position: absolute;
            left:0;
            top:0;
            animation: width 1s ease-in-out;
            transition: width 1s ease-in-out;
        }
    }
}

@keyframes width {
    from {
        max-width: 0%
    }
    to {
        max-width:100%
    }
}

.data-highlight {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin:0;
    color:var(--color-text-muted);
    .number {
        font-weight: 600;
        font-size: 48px;
        line-height: 52px;
        display:block;
        color: var(--color-black);
    }
}

.fit-score {
    position: relative;
    .recharts-wrapper {
        margin:auto;
    }
    .infographic-text {
        position:absolute;
        left:50%;
        // top:50%;
        bottom:0;
        transform:translateX(-50%);
        max-width:200px;
        max-height:200px;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom:12px;

        .current-value {
            font-size:64px;
            line-height:1;
            font-weight:600;
            margin-top:4px;
        }
        .avg-value {
            color: #16797A;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
        }
    }
}
.fit-score-legend {
    margin:auto;
    margin-top:40px;
    font-size: 18px;
    line-height: 24px;
    max-width:300px;
    
    table {
        width:100%;
        caption {
            text-align:left;
            font-weight:bold;
            margin-bottom:6px;
        }
        th {
            text-align:left;
            font-weight:normal;
            padding: 6px 0;
        }
        td {
            text-align:right;
            font-weight:bold;
            padding: 6px 0;
        }
    }

    .circle {
        display: inline-block;
        width:16px;
        height:16px;
        border-radius: 8px;
        margin-right:8px;
    }

    .color-exp {
        background-color: #9901A5;

    }
    .color-edu {
        background-color: #6D0176;
    }
    .color-abil {
        background-color: #57015E;
    }
}
.visibility-marker {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:10px;
    color:var(--color-text-light);
    text-align:center;
    border-top:1px solid var(--color-border);
    margin-top:40px;
    padding-top:24px;

    span {
        vertical-align: middle;
    }
}