header.vertical-nav {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media (min-width: 1025px){
        position: fixed;
        left: 0;
        width: 240px;
    }
    .hamburger {
        display: none;
    }
    .logo {margin-left:14px;}
    @media screen and (max-width: 1024px) {
        min-height: 0;
        .logo { display: none; }
        .hamburger {
            display: block;
            position: absolute;
            top: 45px;
            left: 24px;
            z-index: 11;
            &.fixed {
                position: fixed;
            }
        }
    }

    nav {
        display: block;
        @media (max-width: 1024px){
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            background: var(--color-medium-blue);
            width: 70vw;
            z-index: 10;
            padding-top: 50px;
            padding-left: 8px;        }
    }
    @media (max-width: 1024px){
        padding: 0 !important;
        .nav-menu {
            display: block;
            &.hidden {
                display: none;
            }
        }
        }

}


.nav-links {
    list-style-type:none;
    margin:20px 0;
    padding:0;
    li {
        padding: 4px 0;
    }

    li a {
        display:flex;
        align-items: center;
        justify-content: flex-start;
        gap:16px;
        color: var(--color-text-muted);
        font-size:18px;
        line-height:24px;
        padding: 8px 12px;
        border-radius:var(--card-corner-default);
        text-decoration:none;
        .icon svg{display: block;}

        &.active {
            color: var(--color-white);
            font-weight:700;
        }
        &:hover {
            background: rgba(255,255,255,0.1);
        }
    }
}