.search-input {
    position: relative;
    .search-icon {
        position: absolute;
        left:16px;
        bottom:50%;
        transform: translateY(50%);
        color: var(--color-text-light);
    }
    .gr-input {
        padding-left: 52px;
        border-radius: 100px;
    }
}
.search-container {
    position: relative;
    z-index: 2;
    .autocomplete-box {
        position: absolute;
        width:100%;
        background-color:var(--color-white);
        border: 1px solid var(--color-border);
        border-radius: var(--input-corner);
        padding: 20px;
        z-index: 1111;
        color: var(--color-black);
    }
    .suggestion-results {
        margin:0;
        padding:0;
        list-style-type:none;
        .suggestion-group {
            margin-bottom: 10px;

            .suggestion-title {
                font-weight:600;
                // padding:0 10px;
            }
            // &:last-of-type {
            //     margin-bottom:0;
            // }
        }
    }
    .suggestion-list {
        margin:0;
        padding:0;
        list-style-type:none;

        li {
            padding: 8px 10px;
            line-height:18px;
            border-radius:2px;
            .icon {
                vertical-align: middle;
            }
            .suggestion {
                margin-left:10px;
                vertical-align: middle;

            }
            &:hover {
                background-color: var(--color-light-200);
            }
        }
    }
}

.activity-list-search {
    display: flex;
    align-items: center;
    padding: 0 80px;
    margin-bottom: 20px;
   /* width: 65%;*/
   
    .search.search-input {
        flex: 1;
        padding-right: 20px;
    }
}
