.skeleton {
    --color-skeleton: var(--color-light-grey);
    --color-skeleton-dark: #D2D6F5;

    opacity: 1;
    &.employer {
        animation: pulse-black 1s infinite;
    }
    header {
        height: 105px;
        .logo {
            background: var(--color-skeleton);
            height: 30px;
            width: 100px;
            margin-left: 80px;
        }
        .avatar {
            background: var(--color-skeleton);
            position: absolute;
            right: 96px;
            height: 42px;
            width: 42px;
            border-radius: 100px;
        }
    }
    &.donut {
        position: relative;
        width:100%;
        height: 230px;
        border-radius: 10px;
        border-top-right-radius: 50%;
        border-top-left-radius:50%;
        // margin: 40px 80px;
        background: var(--color-skeleton);
        display: block;
        
    }
    &.fit-score-legend {
        position: relative;
        
        .table-row {
            display: block;
            background: var(--color-skeleton);
            border-radius: 4px;
            height: 24px;
            margin: 8px 0;
        }
    }
    .section {
        position: relative;
        margin: 40px 80px;
        background: var(--color-skeleton);
        height: 180px;
        display: block;
        border-radius: 10px;
        &:first-of-type {
            height: 210px;
            margin: 0;
            &::before {
                content: "";
                display: block;
                height: 45px;
                width: 440px;
                background: var(--color-skeleton-dark);
                position: absolute;
                top: 40px;
                left: 81px;
                opacity: .76;
            }
        }
    }
    .card-list {
        padding: 0 80px;
        margin-top: -80px;
        .card {
            height: 250px;
            border-color: var(--color-skeleton);
            border-width: 3px;
            &::before {
                content: "";
                height: 100px;
                width: 100px;
                display: block;
                background: var(--color-skeleton);
            }
            &::after {
                content: "";
                height: 20px;
                width: 100%;
                display: block;
                background: var(--color-skeleton);
                margin-top: 20px;
            }
        }
        
    }

    &.employee {
        header {
            height: 85px;
            .logo {
                margin-left: 20px;
                margin-top: -23px;
            }
        }
        .h1 {
            margin: 0 40px;
            width: 68%;
            height: 28px;
            background: var(--color-skeleton);
        }
        .card {
            margin: 40px 16px;
            height: 400px;
            &::before {
                content: "";
                height: 100px;
                width: 100px;
                display: block;
                background: var(--color-skeleton);
                animation: pulse-black 1s infinite;
            }
            &::after {
                content: "";
                height: 20px;
                width: 100%;
                display: block;
                background: var(--color-skeleton);
                margin-top: 20px;
                animation: pulse-black 1s infinite;
            }
        }
    }

    h2 {
        margin: 0;
        width: 68%;
        height: 24px;
        background: var(--color-skeleton);
    }

    .card-carousel {
        .card {
            width: 300px;
            height: 200px;
            
            .table-row {
                display: block;
                background: var(--color-skeleton);
                border-radius: 4px;
                height: 24px;
                margin: 10px 0;
            }
        }
    }

    &.course-details {
        padding: 24px;

        .img {
            height: 200px;
            width: 200px;
            margin: 20px auto;
            border-radius: 4px;
            background: var(--color-skeleton);
        }

        h2 {
            margin: 0;
            width: 68%;
            height: 40px;
            border-radius: 4px;
            background: var(--color-skeleton);
            margin: 20px auto;
            width: 70%;
        }
        .table-row {
            display: block;
            background: var(--color-skeleton);
            border-radius: 4px;
            height: 24px;
            margin: 8px 0;
        }
    }

    @keyframes pulse-black {
        0% {
            opacity: .4;
        }
        
        70% {
            opacity: .8;
        }
        
        100% {
            opacity: .4;
        }
    }

}