.pillar-page {
    
    h2, h3 {font-weight:700;}

    &.skill, &.occupation, &.pathway {
        .breadcrumb-container {display: none;}
    }
    

    .section-row {
        display:flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap:40px;
        margin-bottom:40px;
        position: relative;
    }
    .page-info {
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;

        .badge {
            position: relative;
            max-width: 120px;
            margin-right:40px;
            img {
                max-width:100%;
                width:100%;
            }
            .chip-highlight {
                position: absolute;
                top:0;
                left:0;
                transform:translate(80%, 0%);
            }
        }
        .page-title {
            
            .chip-label {vertical-align: middle;}
        }
        @media (min-width:1025px) {
            p {
                font-size:16px;
            }
        }
        
    }
    .intro {
        padding: 20px var(--outside-space) 40px var(--outside-space);
        margin-bottom:20px;
        .card-list {
            --gridGap: 8px;
            // overflow-x:auto;
            // flex-wrap:nowrap;
            display: grid;
            grid-template-columns: calc(50% - var(--gridGap)) calc(50% - var(--gridGap));
            // grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
            grid-auto-rows: auto;
            grid-gap: calc(2 * var(--gridGap));
            overflow: hidden;
            .card {
                color: var(--color-black);
                // flex: 1 0 150px;
                text-align:left;
                min-height:126px;
                // width: 164px;
                // flex:none;
                .label {
                    text-overflow: ellipsis;
                    overflow:hidden;
                }
            }
        }
        .button-primary {
            white-space: nowrap;
        
        }
    }
    section {
        padding: 20px var(--outside-space);

        h2 {
            margin-bottom: 1rem;
        }
        p:last-child {
            margin-bottom:0;
        }
        &.discover {
            margin-top: 48px;
            padding-top:64px;

            .card-list {
                .card {
                    width:100%;
                    flex:none;
                    // flex: 0 0 calc(50% - 8px);
                }
            }
        }
       
    }
    .section-header {
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap:10px;
    }


    @media (min-width:1025px) {
       &:not(.skill), &:not(.occupation), &:not(.pathway)  {
        background-color: var(--color-light-bg);
        .breadcrumb-container {display: block;margin-bottom:24px;}
       }
        .section-row {
            flex-direction: row;
            align-items: flex-start;
        justify-content: space-between;
        }
        .page-info {
            flex-direction: row;
            align-items: flex-start;

            .badge {
                text-align:center;
                .chip-highlight {
                    position: relative;
                    // top:0;
                    // left:0;
                    transform:none;
                }
            }
            
        }
        section {
            padding: 20px 80px;
            // margin-bottom:80px;
        }
        section.discover {
            .card-list .card {width:calc(50% - 8px); }
        }
        .intro {
            padding: 32px 80px 20px 80px;
            position: relative;
            margin-bottom:0;
            background: linear-gradient(83.93deg, #1D1A26 51.9%, #342C47 80.98%, #655591 107.95%);
           
            .button-primary {
                
                color:var(--color-black);
                white-space: nowrap;

                &:before {
                    background:var(--color-white);
                }
            
            }
           
            &.has-card-list, &:has(.card-list) {
                &::after {
                    // content: '';
                    width:100%;
                    height:30%;
                    background-color: var(--color-light-bg);
                    position: absolute;
                    bottom:0;
                    left:0;
                    right:0;
                }
            }
            
            .card-list {
                position: relative;
               z-index: 1;
               overflow:visible;
            //    display: flex;
            //    flex-direction: row;
            //    flex-wrap:wrap;
               
            //    grid-template-columns: repeat(auto-fit, minmax(200px,calc(25% - 12px)));
                .card {
                    width:auto;
                    // flex:0 0 calc(25% - 12px);
                }
                &::after {
                    content: '';
                    width:calc(100% + 160px);
                    height:100%;
                    background-color: var(--color-light-bg);
                    position: absolute;
                    bottom:-50px;
                    left:-80px;
                    right:-80px;
                    z-index: -1;
                }
            }
        }
        &.skill .intro {
            background: linear-gradient(83.93deg, #291715 51.9%, #4C2523 80.98%, #723432 107.95%);
        }
        &.occupation .intro, &.pathway .intro {
            background: linear-gradient(83.93deg, #1B2B31 51.9%, #234C5A 80.98%, #29657A 107.95%);
        }
        
    }

    @media (min-width:1201px) {
       .intro .card-list {
            grid-template-columns: repeat(4, calc(25% - 12px));
        }
    }
}
.pageWrapper .pillar-page {
    
    .intro {
        margin-bottom:36px;
        padding-top:32px;

        h1 {
            font-size:32px;
            margin-bottom:16px;
        }
        .page-info {
            max-width:66%;

            @media screen and (max-width: 1024px) {
                max-width: 95%;
            }
        }
        .breadcrumb-container {
            display: block;
            margin-bottom:24px;
                text-align:left;
            
                a, button {color: var(--color-white);}
        
        }
    }
    .overview {
        h3 {
            margin-top:32px;
            margin-bottom:8px;
            + p {margin-top:0;}
        }
    }

   
}
.pageWrapper.pillar {
    @media (max-width:1024px) {
        padding-bottom:40px;
        &:before {
            min-height:400px;
            background-size: 100% 400px;
        }
        .intro.intro.dark-bg {
            position: relative;
            background: linear-gradient(0deg, #05112A 80%, rgba(0,0,0,0) 100%);
            // background: var(--color-gradient-dark);
            text-align:center;
            margin-bottom:0;

            .badge {margin-right:0;
            margin-bottom:10px}
        }
        
        &.employee {
            .employee-nav {display: none;}
            > main header {display: none;}
        }
    }
   
}

.pillar-page.industry {
    section .card {
        margin-bottom: 10px;
    }
    section:not(.intro){
        padding: 0 20px;
    }
    .section {
        margin: 20px 0;
        p {
            margin: 0;
        }
    }
}

.modal.sheet .intro.dark-bg {
    background:transparent;
    color: var(--color-black);
    margin-bottom:0;
    padding-bottom:0px;
    h1 {
        color: var(--color-black);
    }
    .page-info {
        max-width: none !important;
    }
    .breadcrumb-container {
        display: none !important;
    }
}