.radialChart {
    position: relative;
    .recharts-responsive-container {
        height: 255px !important;
    }
    .chartLabel {
        position: absolute;
        bottom: 95px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 80px;
    }
    .impactLabel {
        text-align: center;
        color: #8A8A8A;
        .label {
            padding: 0;
        }
    }
}