.account-menu-container {
    position: relative;
    z-index: 11;
    .button-unstyled {
        position: relative;
        padding-right:18px; /*create space for arrow*/
    }
    .avatar {
        border: 2px solid var(--color-black);
        border-radius: 100px;
        padding-top: 0;
        height: 36px;
        width: 36px;
        display: block;
        line-height: 36px;
        font-weight: bold;
        overflow: hidden;
        img {
            width: auto;
            height: 110%;
        }
        @media (max-width: 1024px) {
            border-color: var(--color-white);
        }
    }
    .icon-18 {
        position: absolute;
        top: 10px;
        right: 0px;
        color: var(--color-black);
        @media (max-width: 1024px){
            color: var(--color-white);
        }
        svg {
            fill: currentColor;
        } 
    }
    .account-menu {
        display: block;
        position: absolute;
        background: var(--color-white);
        width: 200px;
        text-align: right;
        right: -13px;
        padding: 10px;
        top: 50px;
        border-radius: 5px;
        box-shadow: 2px 3px 8px 0px rgba(0,0,0,50%);
        a {
            display: block;
            padding: 6px 8px;
        }
        &.hidden {
            display: none;
        }
    }
}