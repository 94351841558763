.pageWrapper {
    background: var(--color-gradient-dark);

    &:before {
        content: "";
        z-index: 0;
        position: absolute;
        width: 490px;
        height: 500px;
        transform: skewX(35deg);
        left: -170px;
        background: linear-gradient(rgba(255,255,255,.08), rgba(255,255,255,0));
    }

    > header {
        padding: 70px 20px;
        z-index: 10;
    }

    nav {
        width:100%;
        color: var(--color-white);
    }

    main {
        background: var(--color-light-bg);
        border-top-left-radius: var(--card-corner-large);
        margin-top:40px;
        //z-index: 1;
        width: calc(100vw - 240px);
        scrollbar-gutter: auto;
        @media (max-width: 1024px){
            background: transparent;
            margin-top: 0;
        }

        > header {
            // min-height:100px;
            padding: 32px 80px;
            @media screen and (max-width:1024px){
                padding: 12px 21px;
            }
        }

        section {
            padding: 32px 80px;
            @media screen and (max-width:1024px){
                padding: 8px 21px;
            }

            h2 {
                font-size:32px;
                line-height:36px;
                margin-bottom:20px;
            }

            &.dark-section {
                background: var(--color-gradient-dark-alt2);
                color: var(--color-white);
                padding-top: 40px;
                padding-bottom:40px;
                margin-top:20px;
                margin-bottom:20px;
                h2 {
                    color: var(--color-white);
                    margin-bottom: 24px;
                }
                h3 {
                    font-weight:700;
                    font-size:18px;
                    margin-bottom:8px;
                }
                .overflow-scroll {
                    overflow-y: auto;
                    margin-bottom:24px;
                    flex-wrap:nowrap;
                    gap:8px;

                    &:last-child {
                        margin-bottom:0;
                    }
                }
                .card-list {
                    margin-top: 0;
                }
            }
            &.teal-gradient-bg {
                padding-top: 40px;
                padding-bottom:40px;
                margin-top:20px;
                margin-bottom:20px;  
            }

            &.intro {
                position: relative;
                
                //padding-bottom:60px;
                .card-list {
                    // position: absolute;
                    // width:calc(100% - 160px);
                    // transform:translateY(20px);
                }
                @media (max-width: 1024px) {
                    h1 {
                        color: var(--color-white);
                    }
                }
                .light-bg {
                    background: var(--color-light-bg);
                    margin: 25px -16px 0px -16px;
                    padding: 25px;
                }
                .top-card {
                    background-color: var(--color-medium-blue);
                    color: var(--color-white);
                    border-radius: var(--card-corner-default);
                    padding: 24px;
                    text-align: center;
                    border: 0;
                    margin-top: var(--column-gap);
                    margin-bottom: var(--column-gap);
                    max-width: 600px;
                    margin-left: auto;
                    margin-right: auto;
                    opacity: 0;
                    transition:opacity 0.3s ease-in-out;
                    > a {text-decoration: none;}
                   
                    .progress-label {font-weight:700;color: var(--color-white);}
                    .infographic-text {color: var(--color-white);}
                    .score-container {
                        position: relative;
                        margin:12px auto;
                        text-align:center;
                        .progress-ring {
                            margin:auto;
                        }

                        .chip-highlight {
                            position: absolute;
                            right:calc(50% - 60px);
                            bottom:4px;
                            // transform:translateX(25%);
                            height:40px;
                            width:40px;
                            // top:-8px;
                            background-color: #9800A5;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 24px;
                            vertical-align: middle;
                            text-align:center;
                            padding: 8px;
                            opacity:0;

                        }
                    }
                    .badge {
                        img {
                            width: 100%;
                            height: auto;
                            max-width: 25%;
                        }
                        .badgeName {
                            font-size: 24px;
                            line-height: 24px;
                            margin-top:  var(--column-gap);
                            margin-bottom: var(--column-gap);
                        }
                        .issuer {
                            font-size: 18px;
                            line-height: 24px;
                            margin-bottom: var(--column-gap);
                        }
                    }
                    h2 {
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 8px;
                    }
                    .infographic {margin-top:32px;}
                    button {
                        padding: 0.5em 2em
                    }
                    a, .link-btn, .card-link {
                        color: var(--color-bright-teal);
                    }
                }
               
                  
                 
                  
                  .hidden {display:none;transform:scale(0);}
                  
                  .top-card.card--visible {
                    opacity:1;
                    .chip-highlight {
                        // opacity:1;
                        animation: fadeInChip 0.3s ease;
                        animation-delay: 0.8s;
                        animation-fill-mode: forwards;
                    }
                  }
                  
                  .card-container {
                    position:relative;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    justify-content:center;
                    overflow:hidden;
                  }
                  .card-container .top-card {
                    width:100%;
                    // position:absolute;
                    top:0;
                  /*   left: 50%; */
                    
                  }
            }

            &.dark-bg, &.dark-gradient-bg {
                background-color: var(--color-medium-blue);
                color: var(--color-white);
                @media (max-width: 1024px){
                    background: transparent;
                    &.employee-experience, &.discover {
                        background-color: var(--color-medium-blue);
                    }
                }

                .card {
                    color: var(--color-black);
                    @media (max-width: 1024px){
                        &.user-info {
                            color: var(--color-white);
                        }
                    }
                }
            }

            .section-header {
                display:flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap:10px;
            }

        }
    }

    @media (min-width:1025px) {
        display:grid;
       //grid-template-columns: 240px calc(100% - 240px);
        min-height:100vh;
        padding-left: 240px;

        &.notifications {
            display:block;
            padding:0;
            main {margin-top:0;width:100%;border-radius:0;}
        }
        &.employee {
            padding-bottom:100px;
        }
        .intro {
            padding: 40px 80px 40px 80px;
        }
        // main section.dark-section {
        //     padding: 20px 80px;
        // }
    }

    @media (max-width: 1024px){
        position: relative;
        background: var(--color-light-bg);
        padding-bottom:100px;
        min-height:calc(100vh - 20px);

        &.pillar {
            margin-bottom:20px;
        }
        > header {
            display:flex;
            align-content: center;
            padding: 0 var(--outside-space);
            color:var(--color-white);
            min-height:70px;
            position: relative;
    
            .actions {
                display:flex;
                align-items: center;
                gap:24px;
                color:var(--color-white);
                .avatar {border-color: var(--color-white)}
                .icon-18, .icon-24 {color: var(--color-white)}
            }
        }
        > main {
            padding: 0;
            position: relative;
            width: 100%;
    
            section {
                padding: 20px var(--outside-space);
                
                h2 {
                    margin-bottom:24px;
                }
            }
        }
    
        &:before {
            content: "";
            background: transparent;
            background-image: url("../images/background-swoop.svg");
            background-repeat: no-repeat;
            background-size:100% 100%;
            width:100%;
            min-height: 45vh;
            position: absolute;
            left:0;
            right:0;
            top:0;
            transform: none;
            z-index:0;
        }
        
    
        .user-info {
            background-color: var(--color-medium-blue);
            color:var(--color-white);
            border-radius:8px;
            padding:40px 24px 24px;
            text-align:center;
            border:0;
            margin-bottom:var(--column-gap);
            max-width:600px;
            margin-left:auto;
            margin-right:auto;
    
            .avatar {
                width:180px;
                height:180px;
                border:4px solid var(--color-white);
                overflow:hidden;
                border-radius:50%;
                margin: 24px auto;
                img {
                    width: 100%;
                    max-width:100%;
                    transform:scale(1.05);
                }
            }
            .user-name {
                font-size:2em;
            }
            .user-location {
                color: var(--color-bright-teal);
                margin:12px 0 0 0;
                .icon {
                    vertical-align: middle;
                }
            }
            .user-occupation {
                margin:20px 0 0 0;
            }
        }
        .profile-progress {
            text-align:center;
            max-width:600px;
            margin-left:auto;
            margin-right:auto;
            .progress-bar-container {
                margin: 24px 0;
            }
    
        }
        .popular-programs {
            .card {
                // height:auto;
            }
        }
        section.dark-gradient-bg {
            padding-top:40px;
            padding-bottom:40px;
            margin-bottom:40px;
            
        }
        .overflow-scroll {
            overflow-x:auto;
            display:flex;
            flex-direction:row;
            gap:var(--column-gap);
            .card {max-width:310px;flex:none;}
        }
        .employee-skills {
            h3 {
                font-weight:700;
                margin-bottom:16px;
            }
            .card-column {
                display:flex;
                flex-direction:column;
                gap: var(--column-gap);
                flex:none;
            }
            .card {
                color: var(--color-black);
                .chip-label {
                    border-color: var(--color-black);
                    margin: 0px;
                    display: inline-block;
                }
            }
            .card-row {
                display:flex;
                flex-direction: row;
                align-items: flex-start;
                gap:var(--column-gap);
            }
        }
    
        .badge-list {
            list-style-type:none;
            margin:0;
            padding:0;
    
            li {
                padding: 8px 0;
                &:first-of-type {
                    padding-top:0;
                }
            }
            .badge-item {
                display:flex;
                align-items: center;
                gap:20px;
            }
            .badge {
                max-width:68px;
                img {
                    width:100%;
                    max-width:100%;
                }
            }
        }
        .experience-entry {
            margin-bottom:32px;
            p {
                margin-bottom: 1rem;
            }
            .company-logo {
                display: flex;
                align-items: center;
                gap:10px;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                margin-bottom:16px;
                .company-icon {
                    width:40px;
                    height:40px;
                    overflow:hidden;
                }
            }
            .experience-title {
                font-weight: 700;
                font-size: 32px;
                line-height: 32px;
                /* or 100% */
    
                letter-spacing: 0.4px;
            }
            .experience-duration {
                font-weight: 500;
                font-size: 22px;
                line-height: 24px;
                color: var(--color-text-light);
                margin-top:0.75rem;
                margin-bottom:1rem;
            }
            
        }
        
        @media (min-width:1025px) {
                .employee-skills {
                    .card-column {
                        flex-direction: row;
                    }
                }
        }
    }
    &.employer.employees {
        section {
            @media (max-width: 1024px){
                background: var(--color-light-bg);
            }
        }
    }
}

.reverse-logo {
    display: none;
    @media (max-width: 1024px){
        display: block;
    }
}
.employer .reverse-logo {
    margin-left: 40px;
}

.company-name {
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:10px;
   > img {
        height:40px;
    }
    .company-logo {
        display:inline-flex;
        justify-content: center;
        align-items: center;
        width:50px;
        height:50px;
        border-radius:50%;
        background-color:var(--color-white);
        border:1px solid var(--color-border);
        padding:8px;

        img {
            flex:none;
            width:100%;
        }
        &.dark {
            background-color:var(--color-black);
            border-color:var(--color-black);
        }
    }
    @media screen and (max-width: 1024px) {
        display: none;
        & *  {
            display: none !important;
        }
    }
}
@keyframes cardExit{
    0% {
      transform: translate(0)
    }
    100% {
      transform: translateX(150%) scale(0);
    }
  }
  @keyframes cardEnter{
    0% {
      opacity:0;
      transform: translateX(-30%) scale(0.4);
    }
    100% {
      opacity:1;
      transform: translateX(0) scale(1);
    }
  }

  @keyframes fadeInChip {
    0% {
        transform:translateY(10px) scale(1);
        opacity:0;}
    80% {
        transform:translateY(10px) scale(2);
    }
    100% {
        transform:translateY(0px) scale(1);
        opacity:1;
    }
  }
  @keyframes fadeIn {
    0% {
        transform:translateY(10px);
        opacity:0;}

    100% {
        transform:translateY(0px);
        opacity:1;
    }
  }

  .card-exit {
    // animation: cardExit 0.9s cubic-bezier(0.32, 1, 0.56, 1);
  animation-delay:0.2s;
  opacity:0;
  }
  .card-enter {
    animation: fadeIn 0.5s cubic-bezier(0.5, 1, 0.89, 1);
    animation-fill-mode: forwards;
    // animation-delay:0.2s;
  }
  .confetti {
   position: absolute;
   width:100%;
   height:100%;
   pointer-events: none;
//    z-index: -1;
  }
