.surveys-page {
    .card-list {
        .card {
            flex: 0 1 400px;
        }
    }
    .section-body {
        display: flex;
        margin-bottom: 25px;
        gap: 20px;
    
        &.card {
            display: block;
        }
        .card, &.card {
            height: auto;
            margin-bottom: 20px;
            > h3 {
                border-bottom: 1px solid #ccc;
                padding-bottom: 10px;
                margin-bottom: 20px;
            }
        }
    
        .labeled {
            display: flex;
            margin: 10px 0;
            .label {
                width: 20%;
                font-weight: bold;
            }
            .value {
                flex: 1;
            }
        }
        .data-table {
            border-top: 1px solid #ccc;
            margin-top: 20px;
            padding-top: 20px;
            table {
                td, th {
                    &:first-child {
                        width: 25%;
                    }
                }
            }
        }
    
        table {
            width: 100%;
            border-collapse: collapse;
            td, th {
                border: 1px solid #ccc;
                text-align: left;
                padding: 10px;
            }
        }
    
        .side-nav {
            padding: 0;
            width: 20vw;
            overflow: hidden;
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    padding: 0;
                    margin: 0;
                    a {
                        display: block;
                        padding: 20px;
                        border-bottom: 1px solid #ccc;
                        color: #000;
                        text-decoration: none;
                        &.active {
                            font-weight: bold;
                            background: #efefef;
                        }
                        &:hover,
                        &:focus {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .right-pane {
            flex: 1;
        }
    
    
    }
}

