.video-player {

    .video-container {
        margin: 0 0 24px;
        position: relative;

        video {
            display: block;
            width: 100%;
            border: none;
        }
        
        .timeline {
            width: 100%;
            padding: 0;

            /*Chrome*/
            input[type='range'] {
                display: block;
                overflow: hidden;
                appearance: none;
                width: 100%;
                height: 10px;
                background: linear-gradient(90deg, rgba(89, 126, 255, 0.2) 0%, rgba(10, 214, 202, 0.2) 100%);
            }
            
            input[type='range']::-webkit-slider-runnable-track {
                appearance: none;
                color: #13bba4;
                margin-top: -1px;
            }
            
            input[type='range']::-webkit-slider-thumb {
                appearance: none;
                width: 1px;
                height: 10px;
                cursor: ew-resize;
                background: #43e5f7;
                box-shadow: -1000px 0 0 1000px #43e5f7;
            }

            // input[type='range']::-webkit-slider-thumb {
            //     appearance: none;
            //     width: 16px;
            //     height: 16px;
            //     border-radius: 8px;
            //     cursor: ew-resize;
            //     background: #5880FF;
            //     box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25), -1000px 0 0 1000px #43e5f7;
            // }

            
            /** FF*/
            input[type="range"]::-moz-range-progress {
                // background-color: #43e5f7; 
                background: linear-gradient(90deg, #0AD6CA 0%, #597EFF 100%);
                height: 10px;
            }

            input[type="range"]::-moz-range-thumb  {
                appearance: none;
                border: none;
                width: 1px;
                height: 10px;
                cursor: ew-resize;
                background: #597EFF;
            }  
        }

        .play-btn {
            display: block;
            margin: 0;
            padding: 0;
            appearance: none;
            border: none;
            position: absolute;
            width: 56px;
            height: 56px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: transparent;

            &.hidden {
                display: none;
            }
        }
    }

    .video-details {
        margin: 0 0 32px 16px;
        padding: 0 0 0 40px;
        position: relative;
    }

    .video-title {
        margin: 0 0 4px 0;
        padding: 0;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: var(--color-white);
    }

    .video-author {
        margin: 0 0 8px 0;
        padding: 0;
        font-size: 12px;
        line-height: 1.33;
        color: var(--color-white);
    }

    .video-author-avatar {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        font-size: 12px;
        line-height: 32px;
        color: var(--color-white);
        background-color: #A85656;
        text-align: center;
        text-transform: uppercase;
    }

    .video-meta {
        margin: 0 0 12px;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0;
            padding: 0;
            display: inline;
            color: var(--color-text-muted);
            font-size: 12px;
            line-height: 1.3;

            + li {
                margin-left: 1em;
            }
        }
    }
}