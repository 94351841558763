.card {
    position: relative;
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: var(--card-corner-default);
    padding:24px;
    // padding-bottom: 13px;
    height: 100%;

    > a {
        color: inherit;
        text-decoration: inherit;
        
    }
    // &:hover {
    //     // outline: 2px var(--color-border) solid;
    //     // outline-offset: -2px;
    // }
    &.user-info:hover {
        outline: none;
    }

    &.no-padding {
        padding:0;
    }

    .progress-ring {
        margin-left: auto;
        margin-right: auto;
    }
    .progress-label {
        margin-top: 5px;
        font-weight: bold;
    }
    
    .card-header {
        display:flex;
        flex-direction:row;
        align-items: flex-end;
        justify-content: space-between;
        gap:10px;
        margin-bottom:20px;
        .card-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
        }
        a {
            font-size:18px;
        }
    }
    .card-title {
        display: block;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
    }
   .card-footer {
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        gap:12px;
        margin-top:32px;
   }
    .card-text {
        display: block;
        font-size: 18px;
        line-height: 22px;
        color: var(--color-text-light);

        &:last-child {margin-bottom:0;}
    }
    .card-link {
        font-size: 18px;
        line-height: 24px;
        color: var(--color-teal);
    }
    .feature-list {
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap:10px;
        grid-row-gap:16px;
        list-style:none;
        margin:0;
        padding:0;

        li {
            // white-space: nowrap; 
            hyphens: auto;
            vertical-align: middle;
            white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow:hidden;
            span {
                vertical-align: bottom;  
            }
            .circle-icon {
                color: var(--color-text-light);
            }
        }
    }
    .circle-icon {
        display: inline-block;
        width:29px;
        height:29px;
        background-color: var(--color-light-200);
        text-align:center;
        border-radius:50%;
        color: var(--color-text-muted);
        line-height:32px;
        margin-right:8px;
        padding:2px;
    }
    &.dark {
        background-color: var(--color-medium-blue);
        border-color: var(--color-medium-blue);
        color:var(--color-white);

        a, .link-btn, .card-link {
            color: var(--color-teal-bright);
        }
    }
    &.large {
        border-radius: var(--card-corner-large);
    }

    &.snapshot {
        display:flex;
        flex-direction:column;
        text-align:left;
        .number {
            font-size: 64px;
            line-height: 72px;
            font-weight:600;
        }
        .label {
            font-size: 24px;
            line-height: 28px;
        }
    }
    &.program {
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        justify-content: flex-end;
        .program-img {
            display: block;
            width:100%;
            max-width:100%;
            margin: 0px auto 10px auto;
            img {
                width:100%;
                max-width:100%;
            }
        }
    }
    .info-btn {
        position: absolute;
        right:8px;
        top:10px;
        .info-icon {
            display: inline-block;
            width:22px;
            height:22px;
            background-color: var(--color-light-200);
            text-align:center;
            border-radius:50%;
            color: var(--color-text-muted);
            padding:2px;
        }
    }
    &.overview {
        position: relative;
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 16px 24px;
        
        .label {
            font-size: 18px;
            line-height: 24px;
            font-weight:500;
            padding-right:40px;
            margin-bottom:8px;
        }
        .number {
            font-size:48px;
            line-height:52px;
            font-weight:600;
            margin-bottom:auto;

            // @media (min-width:1025px) {
            //     font-size:48px;
            // line-height:52px;
            // }
        }
        .footnote {
            font-size: 14px;
            line-height: 18px;
            margin-top:20px;
        }
        .button-link {
            margin-top:40px;
        }
        .graph-container {
            margin-top: 12px;
            & > div {
                margin-bottom: 12px;
            }
            & > svg {
                width: 100%;
                height: auto;
            }
            &.trend-up {
                .chip-highlight {
                    svg {
                        fill: var(--color-plum);
                        position: relative;
                        top: 3px;
                        left: 2px;
                    }
                }
            }
            &.trend-down {
                .chip-highlight {
                    svg {
                        fill: var(--color-state-orange-dark);
                        position: relative;
                        top: 3px;
                        left: 2px;
                    }
                }
            }
        }
    }
    &.learning {
        padding: 24px 16px;

        .learning-link {
            color:inherit;
            text-decoration:none;
        }
        .program-img {
            display: block;
            margin: 0px auto 10px auto;
            max-width: 140px;
            img {
                width:100%;
                max-width:100%;
            }
        }

        .card-title {
            min-height:48px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        p.card-text {
            text-overflow: ellipsis;
            overflow:hidden;
            white-space: nowrap;
            margin: 10px 0;
        }
        .button-tertiary {
            width:100%;
            margin-top:2em;
        }

        &.my-course {
            padding: 24px 16px;
        }
    }

    &.video-course {
        padding: 0;
        border-radius: 0;
        background-color: transparent;
        border: none;
        &:hover {
            outline: none;
        }

        .poster {
            margin: 0 0 8px 0;
            display: block;
            border-radius: 4px;
            position: relative;
            overflow: hidden;

            img.video-img {
                display: block;
                width:100%;
                max-width:100%;
            }

            img.play-btn {
                position: absolute;
                width: 56px;
                height: 56px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            img.complete {
                position: absolute;
                top: 8px;
                right: 5px;
                width: 28px;
                height: auto;
            }
        }

        progress {
            margin: 0;
            padding: 0;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 6px;
            -webkit-appearance: none;
            appearance: none;
            border: none;

            &::-webkit-progress-bar {
                background: linear-gradient(135deg, rgba(238, 81, 46, 0.2) 0%, rgba(223, 0, 107, 0.2) 63.44%, rgba(189, 0, 113, 0.2) 100%);
            }

            &::-webkit-progress-value {
                background: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
            }
        }

        .video-details {
            margin: 0;
            padding: 0 0 0 40px;
            position: relative;
        }

        .video-title {
            margin: 0 0 4px 0;
            padding: 0;
            font-size: 14px;
            line-height: 16px;
            min-height: 32px;
            font-weight: 400;
            color: var(--color-white);

            // Line clamping
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }

        .video-author {
            margin: 0 0 8px 0;
            padding: 0;
            font-size: 12px;
            line-height: 1.33;
            color: var(--color-white);
        }

        .video-author-avatar {
            margin: 0;
            padding: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 32px;
            height: 32px;
            border-radius: 16px;
            font-size: 12px;
            line-height: 32px;
            color: var(--color-white);
            background-color: #A85656;
            text-align: center;
            text-transform: uppercase;
        }

        .video-meta {
            margin: 0 0 12px;
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            gap: 1em;

            li {
                margin: 0;
                padding: 0;
                color: var(--color-text-muted);
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    &.practice {
        padding: 16px 16px 16px 24px;
        border: 1px solid #535E74;
        background-color: #13203B;
        border-radius: 8px;

        .type {
            margin: 0 0 8px;
            padding: 0;
            font-family: 'TT Commons', sans-serif;
            color: #8A8AA0;
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
        }

        .question {
            margin: 0 0 24px;
            color: var(--color-white);
            font-size: 14px;
            line-height: 28px;
            font-weight: 400;

            // Line clamping
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }

        .answer-indicator {
            position: absolute;
            top: 15px;
            right: 11px;
        }

        .practice-meta {
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            gap: 12px;
            width: 100%;

            li {
                margin: 0;
                padding: 0;
                color: var(--color-text-muted);
                font-size: 12px;
                line-height: 1.3;

                &.format {
                    color: var(--color-white);
                    margin-left: auto;
                }
            }
        }
    }
    &.employeeCard {
        text-align:center;
        .avatar {
            display: block;
            width:110px;
            height:110px;
            border-radius:50%;
            overflow:hidden;
            margin:0 auto 10px auto;
            img {
                width:100%;
                max-width: 100%;
            }
        }
        .employee-name {
            font-weight:700;
        }
        .job-org {
            color: var(--color-text-muted);
            margin-top:8px;
        }
        span {
            display: block;
        }
    }
}

.card-list {
    display:flex;
    flex-direction:row;
    // align-items: flex-start; 
    gap:16px;
    flex-wrap:wrap;
    margin-top: 32px;

    .card {
        flex:1;
        max-width:100%;
    }

}

.learning-list {
    list-style-type: none;
    padding:0;
    margin:0;

    li {
        padding:6px 0;
        button {
            background:transparent;
            
            &:hover, &:focus {
                background-color: var(--color-light-200)
            }
        }
    }
    .go-to-learning {
        display:flex;
        flex-direction:row;
        align-items: center;
        gap:10px;
        h4,p {margin:0;}
        h4 {
            font-weight:700;
            // line-height:24px;
            span {
                color: var(--color-plum);
                display:inline;
                vertical-align: text-top;
                svg {
                    vertical-align: bottom;
                }
            }
        }
        .card-link {font-size:16px}
        .badge {
            max-width:60px;
            flex:none;
            img {
                width:100%;
                max-width:100%;
            }
        }
        .learning-info {
            flex:1;
            max-width:calc(100% - 60px - 24px);
            text-overflow: ellipsis;
                overflow:hidden;
                white-space: nowrap;

            h4, .card-link {
                text-overflow: ellipsis;
                overflow:hidden;
                white-space: nowrap;
                max-width:100%;
            }

            .learning-info-title {
                text-decoration: none;
                color: var(--color-black);
            }
        }
        a.button {
            color: var(--color-text-light) !important;
        }
    }
}

.card-carousel {
    position: relative;
    overflow:hidden;
    width:100%;

    .prev-btn {
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        left:0;
        z-index: 11;
        display: none;;
    }
    .next-btn {
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        right:0;
        z-index: 11;
        display: none;;
    }

    .card-container {
        width:100%;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
       > ul {
            list-style-type: none;
            margin:0;
            padding:0;
            display:flex;
            gap:var(--column-gap);
            transition:transform 0.3s ease;

            > li {
                scroll-snap-align: start;
                flex-shrink: 0;
                flex:none;
            }
        }
    }

    @media (min-width:1025px) {

        &:not(.firstSlide) {
            &:before {
                content:'';
                position:absolute;
                width:80px;
                height:100%;
                left:0;
                top:0;
                bottom:0;
                background: linear-gradient(to right, var(--color-light-bg), rgba(255,255,255,0));
                z-index: 1;
            }
        }
        &:not(.lastSlide) {
            &:after {
                content:'';
                position:absolute;
                width:80px;
                height:100%;
                right:0;
                top:0;
                bottom:0;
                background:linear-gradient(to left, var(--color-light-bg), rgba(255,255,255,0));
                z-index: 1;
            }
        }
        
        .prev-btn, .next-btn {
            display: block;
            &:disabled {
                opacity: 0;
            }
        }
        .card-container {
            position: relative;
            width:100%;
            overflow: hidden;
            ul {
                width:max-content;
                li {
                    .card {height:100%;}
                }
            }
        }

         // For Dark Blue background
         &.dark-blue {
            &:not(.firstSlide) {
                &:before {
                    background: linear-gradient(to right, var(--color-dark-blue), rgba(255,255,255,0));
                }
            }
            &:not(.lastSlide) {
                &:after {
                    background:linear-gradient(to left, var(--color-dark-blue), rgba(255,255,255,0));
                }
            }

            .prev-btn, .next-btn {
                color: var(--color-black);
            }
         }
    }
}

.badges {
    .card.learning {
        margin-bottom: 20px;
    }
}
.card.skills {
    .card-row {
        display: flex;
        justify-content: space-between;
        gap:8px;
    }
}