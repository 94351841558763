.chip-btn, .chip-label {
    display:inline;
    padding:10px 12px;
    background-color: var(--color-white);
    border:1px solid var(--color-border);
    border-radius:4px;
    font-size:18px;
    line-height:18px;
    font-weight:400;
    color: var(--color-black);
    margin: 2px;
    white-space:nowrap;
    text-decoration:none;
    // flex:none;
    &.plum {
        background: rgba(109, 1, 118, 0.6);
        color:var(--color-white);
        // border-color: rgba(109, 1, 118, 0.6);
        border:none;
        &:hover, &:focus {
            color: var(--color-white);
            border-color: var(--color-white);
            text-decoration: underline;
        }
    }
}
.chip-btn:hover,.chip-btn:focus {
    // placeholder
    color: var(--color-teal);
    border-color: var(--color-teal);
    text-decoration: underline;

}

.chip-list {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    gap:8px;
    list-style-type:none;
    margin:0 -4px;
    padding:0;
    .chip-label,.chip-btn {display:inline-block;margin: 0 4px;}
}

/* plum rounded labels */
.chip-highlight {
    display:inline-block;
    background-color: var(--color-light-200);
    color:var(--color-black);
    padding: 0 12px;
    border-radius: 20px;
    font-size:16px;
    line-height:1.5;
    font-weight:700;
    letter-spacing: 0.24px;

    &.plum {
        background: var(--color-plum);
        color:var(--color-white);
    }
    &.teal {
        background: var(--color-bright-teal);
        color:var(--color-black);
    }

    svg {
        position: relative;
        top: 3px;
        left: 2px;
    }
    
}

h2 + .chip-container {
    margin-top: -12px;
    margin-bottom: 20px;
}

