.hero {
    position: relative;
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: var(--card-corner-default);
    padding: 50px;
    .hero-side {
        width: 50%;
        max-width: 450px;
        @media (max-width: 449px){
            width: 100%;
            z-index: 1;
            position: relative;
        }
    }
    img.right-float {
        position: absolute;
        bottom: 0;
        right: -1px;
        height: 120%;
        min-width: 460px;
        @media (max-width: 449px) {
            width: 100%;
            min-width: 0;
            z-index: 0;
            bottom: 0;
            height: auto;
            opacity: .25;
        }
    }
}
.dashboard .hero {
    h2 {
        color: var(--color-black);
    }
}
.intro.with-hero h1::before {
    height: 508px;
}