.pageWrapper.notifications {
    background:var(--color-light-bg);
    &:before {
        height:180px;
        min-height:180px;
        background-position:bottom;
        background-size:cover;
        transform:translateY(-40px)
    }
    .intro {
        position: relative;
        text-align:center;
        min-height:120px;
    }
    .breadcrumb-container {
        position: absolute;
        left:var(--outside-space);
        // top:0;
        color: var(--color-white);
        a, button {
            color: var(--color-white) !important;
        }
    }
}
.pageWrapper main section .notification {
    border-bottom: 1px solid var(--color-border);
    padding: 16px 0;
    display:flex;
    gap:16px;
    align-items: center;

    .icon {
        width:40px;
        height:40px;
        border-radius:50%;
    }
    h2 {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 20px;
        margin-top:0;
        margin-bottom:4px;
    }
    p {
        margin:0;
        color:var(--color-text-light);
        font-size: 1rem;
        line-height: 20px;
    }
    .date {
        font-size:0.875em;
    }
    .notification-info {flex:1;}
    .notification-status {
        min-width:10px;
    }
    .status.new {
        display: block;
        width:8px;
        height:8px;
        background:#FF6b00;
        border-radius:4px;
    }
}

.notification-icon {
    position: relative;

    .status.new {
        display: block;
        width:10px;
        height:10px;
        background:#FF6b00;
        border-radius:5px;
        position: absolute;
        right:10px;
        top:6px;
        border:2px solid #180F38;
    }
}