.employee-detail {
    .actions {
        text-align:right;
        .button-link {
            color: var(--color-bright-teal);
            text-decoration:none;
            margin-left:12px;
        }
    }
    .user-info {
        background-color: var(--color-medium-blue);
        color:var(--color-white);
        
        padding:24px;
        text-align:center;
        border:0;
       
        

        .avatar {
            width:180px;
            height:180px;
            border:4px solid var(--color-white);
            overflow:hidden;
            border-radius:50%;
            margin: 24px auto;
            img {
                width: 100%;
                max-width:100%;
                transform:scale(1.05);
            }
        }
        .user-name {
            font-size:2em;
            margin-bottom:0;
        }
        .user-location {
            line-height:24px;
            color: var(--color-bright-teal);
            margin:12px 0 0 0;
            .icon {
                vertical-align: top;
                svg {
                    display: inline-block;
                }
            }
        }
        
        .user-occupation {
            margin:20px 0 0 0;
        }
    }
    .about-me {
        padding-top:72px;
        padding-bottom:24px;
        p {
            font-size: 22px;
            line-height:28px;
            
        }
    }
    .tab-content {
        // padding: 0 20px;
    }
    .employee-details {
        h3 {margin-bottom:20px;font-weight:700;}
        ul {
            list-style-type: none;
            margin:0;
            padding:0;
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
            font-size:1.125rem;
            // display:grid;
            // grid-template-columns: 50% 50%;
            // grid-auto-rows: 3;
            // grid-auto-flow: column;
            li {
                padding:12px 0;
                    line-height: 22px;
                .icon {
                    display: inline-block;
                    height:24px;
                    margin-right:16px;
                    vertical-align: bottom;
                }
            }
        }
    }

    @media (max-width:1024px) {
        .user-info {
            padding:40px 24px;
            border-radius:8px;
            max-width:600px;
            margin-left:auto;
            margin-right:auto;
            margin-bottom:var(--column-gap);
            margin-top: var(--column-gap);
        }
        .breadcrumb-container {
            // max-width:600px;
            // margin-left:auto;
            // margin-right:auto;
        }
    }
    @media (min-width:1025px) {
        .intro {
            background-color: var(--color-medium-blue);
        }
    }
}

.employee.dashboard {

    .activity-snapshot {
        .card-list {
            display:grid;
            grid-template-columns: repeat(2, minmax(150px, 50%));
            // .card {
            //     flex: 0 0 calc(25% - 8px);
            // }

            @media (min-width:730px) {
                grid-template-columns: repeat(4, minmax(150px, 25%));
            }
        }
    }
}