.detail-page {

    .intro {
        .breadcrumb-container {
            margin-left:80px;
            margin-bottom:20px;
            a {text-transform: capitalize;}
        }
        &:before {content:none;}
    }
    .user-info {
        background-color: var(--color-medium-blue);
        color:var(--color-white);
        padding:24px;
        text-align:center;
        border:0;

        .avatar {
            width:180px;
            height:180px;
            border:8px solid var(--color-white);
            overflow:hidden;
            border-radius:50%;
            // margin: 16px auto;
            margin-bottom:20px;
            img {
                width: 100%;
                max-width:100%;
                transform:scale(1.05);
            }
        }
        .chip-label {
            display: inline-block;
        }
        .user-name {
            // font-size:2em;
            margin-bottom:0;
        }
        .user-location {
            line-height:24px;
            color: var(--color-bright-teal);
            margin:12px 0 0 0;
            .icon {
                vertical-align: top;
                svg {
                    display: inline-block;
                }
            }
        }
        
        .user-occupation {
            margin:16px 0 0 0;
            font-weight:700;
        }

        .user-description {
            color:var(--color-jag-gray);
        }
    }
    .item-details {
        h3 {
            margin-bottom:20px;
            font-weight:700;
            &:first-letter {
                text-transform: uppercase;
            }
        }
        ul {
            list-style-type: none;
            margin:0;
            padding:0;
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
            font-size:1.125rem;
            // display:grid;
            // grid-template-columns: 50% 50%;
            // grid-auto-rows: 3;
            // grid-auto-flow: column;
            li {
                padding:12px 0;
                    line-height: 22px;
                .icon {
                    display: inline-block;
                    height:24px;
                    margin-right:16px;
                    vertical-align: bottom;
                }
            }
        }
        .item-info {
            margin-bottom:40px;
        }
    }


    @media (min-width:1025px) {
        .intro {
            padding-left:0;
            padding-right:0;
            
            
        }
        .user-info {
            display:flex;
            gap:40px;
            background-color: var(--color-dark-blue);
            border-radius:0;
            padding: 20px 80px;
    
            .user-details {
                text-align:left;
            }
        }
        
    }
}