.login {
    
    background: var(--color-gradient-dark);
    height: 100vh;
    color: var(--color-white);
    padding: 1px;

    &:before {
        content: "";
        z-index: 0;
        position: absolute;
        width: 490px;
        height: 500px;
        transform: skewX(35deg);
        left: -170px;
        background: linear-gradient(rgba(255,255,255,.08), rgba(255,255,255,0));
    }

    @media (max-width: 1024px){
        position: relative;
        background: var(--color-light-bg);
        padding-bottom:100px;
    
        &:before {
            content: "";
            background: transparent;
            background-image: url("../images/background-swoop.svg");
            background-repeat: no-repeat;
            background-size:100% 100%;
            width:100%;
            min-height: 45vh;
            position: absolute;
            left:0;
            right:0;
            top:0;
            transform: none;
            z-index:0;
        }
    }

    .logo {
        position: relative;
        z-index: 0;
        margin: 20px 10px;
    }

    form {
        width: 93vw;
        max-width: 500px;
        background: var(--color-white);
        color: var(--color-black);
        padding: 40px;
        border-radius: 10px;
        margin-top: 70px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 1;
        border: 1px solid var(--color-border);
        input {
            margin-bottom: 10px;
            width: 100%;
        }
        img {
            margin-bottom: 30px;
            width: 100%;
            height: auto;
        }
    }

    footer {
        position: absolute;
        bottom: 0;
        width: 99%;
    }
}