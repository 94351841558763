:root {
    --color-black: #020917;
    --color-white: #FEFEFE;
    --color-success: #0CC0AD;
    --color-text-default: #020917;
    --color-text-light: #60646D;
    --color-text-muted: #989BA3;
    --color-light-bg: #F7F9FD;
    --color-teal: #17897c;
    --color-bright-teal: #0CC0AD;
    --color-plum: #6D0176;
    --color-dark-blue: #05112A;
    --color-medium-blue:#212E47;
    --color-light-grey: #E8EEFA;
    --color-light-200: #E8EEFA;
    --color-gradient-dark: linear-gradient(227.6deg, rgba(109, 1, 118, 0.8) 0%, rgba(109, 1, 118, 0) 140vh), #05112A;
    --color-gradient-dark-alt: linear-gradient(83.93deg, #05112A 51.9%, #1A2538 80.98%, #2A3B5B 107.95%);
    --color-gradient-dark-alt2: linear-gradient(-130deg, rgba(109, 1, 118, 0.8) 0.25%, rgba(5, 17, 42, 0) 34.61%), #05112A;
    --color-gradient-teal: linear-gradient(135deg, #0CC0AD 0%, #2E839E 50.57%, #29657A 100%);
    --color-gradient-dark-teal: linear-gradient(-130deg, rgba(12, 192, 173, 0.8) 19.59%, rgba(33, 46, 71, 0.8) 49.78%), #05112A;
    --color-state-orange-dark: #F34A02;
    --color-jag-gray: #a6a6a6;
    --color-border: #DDE3EE;
    --card-corner-default: 16px;
    --card-corner-large: 24px;
    --input-corner: 4px;

    --column-gap: 16px;
    --outside-space: 16px;

    /* --card-shadow: */

    /*carry over from HED styles */
    --color--background-light: #fff;
    --color--background-gray: #fafafa;
    --color--background-medium: #f3f8fb;
    --color--background-medium-gray: #dfe1e1;
    --color--background-dark: #151515;
    --color--ui-01: #f5f5f5;
    --color--ui-02: #fefefe;
    --color--ui-03: #151515;
    --color--ui-04: #505759;
    --color--ui-05: #919191;
    --color--ui-06: #e6e6e6;
    --color--border-01: #dfe1e1;
    --color--border-02: #151515;
    --color--border-03: #919191;
    --color--field: #fff;
    --color--overlay-dark: rgba(21, 21, 21, 0.5);
    --color--overlay-light: rgba(80, 87, 89, 0.5);
    /* --color--gradient-dark: linear-gradient( 45deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.1125) 41.15%, rgba(0, 0, 0, 0.1125) 55.73%, rgba(0, 0, 0, 0.25) 100% ); */
    --color--gradient-light: linear-gradient( 315.12deg, rgba(153, 153, 153, 0.25) 0%, rgba(242, 242, 242, 0.25) 41.15%, rgba(242, 242, 242, 0.25) 55.73%, rgba(153, 153, 153, 0.25) 100% );
    --color--gradient-light-on-dark: linear-gradient( 45.12deg, rgba(204, 204, 204, 0.25) 0%, rgba(242, 242, 242, 0.1125) 41.15%, rgba(242, 242, 242, 0.1125) 55.73%, rgba(204, 204, 204, 0.25) 100% );
    --color--text-primary: #151515;
    --color--text-secondary: #333;
    --color--text-tertiary: #505759;
    --color--text-link: #007a9c;
    --color--text-link-hover: #005d77;
    --color--text-link-active: #004b60;
    --color--text-link-focus: #005d77;
    --color--text-primary-inverse: #fefefe;
    --color--text-secondary-inverse: #fafafa;
    --color--text-tertiary-inverse: #f5f5f5;
    --color--text-link-inverse: #fefefe;
    --color--text-link-hover-inverse: #fafafa;
    --color--text-link-active-inverse: #f5f5f5;
    --color--icon-primary: #151515;
    --color--icon-secondary: #333;
    --color--icon-tertiary: #505759;
    --color--icon-link: #007a9c;
    --color--icon-link-hover: #005d77;
    --color--icon-link-active: #004b60;
    --color--icon-primary-inverse: #fefefe;
    --color--icon-secondary-inverse: #fafafa;
    --color--icon-tertiary-inverse: #f5f5f5;
    --color--icon-link-inverse: #fefefe;
    --color--icon-link-hover-inverse: #fafafa;
    --color--icon-link-active-inverse: #f5f5f5;
    --color--interactive-action: #ffbb1c;
    --color--interactive-action-hover: #151515;
    --color--interactive-action-active: #151515;
    --color--interactive-action-focus: #fff;

    --color--interactive-primary: linear-gradient(135deg, #0CC0AD 0%, #2E839E 50.57%, #29657A 100%);
    --color--interactive-primary-hover: linear-gradient(120deg, #0CC0AD 0%, #2E839E 50.57%, #29657A 100%);;
    --color--interactive-primary-active: #2E839E;
    
    --color--interactive-secondary: #151515;
    --color--interactive-secondary-hover: #505759;
    --color--interactive-secondary-active: #505759;
    --color--interactive-tertiary: #151515;
    --color--interactive-tertiary-hover: #151515;
    --color--interactive-tertiary-active: #151515;
    --color--interactive-action-inverse: #ffbb1c;
    --color--interactive-action-hover-inverse: #ffd06b;
    --color--interactive-action-active-inverse: #ffd06b;

    --color--interactive-primary-inverse: linear-gradient(135deg, #29657A 0%, #2E839E 50.57%, #0CC0AD 100%);
    --color--interactive-primary-hover-inverse: linear-gradient(120deg, #29657A 0%, #2E839E 50.57%, #0CC0AD 100%);
    --color--interactive-primary-active-inverse: #2E839E;
    
    --color--interactive-secondary-inverse: #fff;
    --color--interactive-secondary-hover-inverse: #e1ffff;
    --color--interactive-secondary-active-inverse: #e1ffff;
    --color--interactive-tertiary-inverse: #fff;
    --color--interactive-tertiary-hover-inverse: #fff;
    --color--interactive-tertiary-active-inverse: #fff;
    --color--interactive-focus: #4fa8ff;
    --color--interactive-focus-field: #2f95fc;
    --color--interactive-disabled-01: #e6e6e6;
    --color--interactive-disabled-02: #919191;
    --color--interactive-disabled-03: #767676;
    --color--alert-error: #d30018;
    --color--alert-success: #008638;
    --color--alert-warning: #fc630f;
    --color--alert-information: #151515;
    --color--alert-error-inverse: #eb3d1a;
    --color--alert-success-inverse: #12b2a6;
    --color--alert-warning-inverse: #ff9c1a;
    --color--alert-information-inverse: #fefefe;
    --color--accent-01-light: #ffbb1c;
    --color--accent-01-primary: #ff757a;
    --color--accent-01-medium: #9e007e;
    --color--accent-01-dark: #722362;
    --color--accent-02-light: #d2db0e;
    --color--accent-02-primary: #84bd00;
    --color--accent-02-medium: #008638;
    --color--accent-02-dark: #003a00;
    --color--accent-03-light: #94e7ea;
    --color--accent-03-primary: #12b2a6;
    --color--accent-03-medium: #003057;
    --color--accent-03-dark: #000a31;
    --color--accent-01-gradient: linear-gradient( 259.64deg, #ff8275 4.71%, #9e007e 66.44%, #722351 90.65% );
    --corners-semiround-sm: 0.25em;
  --corners-semiround-md: 0.5em;
  --corners-semiround-pill: 2.5em;
  --button-corners: var(--corners-semiround-pill);
}

@media (min-width: 414px) and (max-width:567px) {
  :root {
    --column-gap: 24px;
    --outside-space: 16px;
  }
  
}
@media (min-width: 808px) {
  :root {
    --column-gap: 24px;
    --outside-space: 32px;
  }
  
}
@media (min-width: 1200px)  {
  :root {
    --column-gap: 24px;
    --outside-space: 48px;
  }
  
}