.pageWrapper {
    &.checkout {
        @media screen and (max-width: 1024px) {
            // background: var(--color-gradient-light) !important;
            color: var(--color-black);
        
            &:before {
                display: none !important; 
            }            
        }

        .back-btn {
            font-weight: 400;
            font-size: 18px;
            line-height: 1.33;
            letter-spacing: 0.24px;
            background: transparent;
            border: 0;
            display: flex;
            align-items: center;

            @media screen and (max-width: 1024px) {
                color: var(--color-black);
            }
        }

        ul.cart {
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-family: 'Hind', helvetica, sans-serif;
            
            li {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;
                gap: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                .item-name {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 1.5;
                }

                .item-cost {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 1.5;
                }

                > img {
                    max-width: 45%;
                }

                .course-specifics {
                    display: flex;
                    flex-direction: column;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.5;
                    white-space: nowrap;
                }

                &.promo-code {
                    align-items: center;
                }

                &.cart-extras {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 12px;

                    > span:nth-child(2n) {
                        text-align: right;
                    }
                }
            }
        }

        .page-action {
            padding: 20px var(--outside-space);
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                @media screen and (max-width: 600px) {
                    width: calc(100% - 24px);
                }
            }
        }

    }
}

.MuiAccordionSummary-root {
    padding: 0 !important;
}

.MuiAccordionSummary-content {
    h4 {
        margin: 0 0 16px 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 1; 
    }
}

.employer-credit {
    border: 1px solid #878D99;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    gap: 20px;
    
    &::before {
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #EA067E;
    }

    p {
        margin: 0 0 8px;
        font-weight: 300;

        &.sub-text {
            font-size: 14px;
        }
    }
}