.pageWrapper.channels {
    background-color: var(--color-dark-blue);
    min-height:100vh;
    padding-bottom:0;

    main {
        padding-top:0;
        > header {
            display: none;
        }
    }
    section.intro {
        padding-top:60px;
        min-height: 245px;
        position: relative;
        overflow:hidden;

        .breadcrumb-container {
            button, a {
                color:var(--color-white);
            }
        }

        &:after {
            content:'';
            width:calc(100% + 200px);
            height:80px;
            background: linear-gradient(to top, var(--color-dark-blue), rgba(0,0,0,0));
            position: absolute;
            bottom:0;
            left:-100px;
            right:-100px;
            z-index: 1;
        }
        h1 {
            position: relative;
            z-index: 2;
        }

        &.video-page {
            background:var(--color-dark-blue);
            height:auto;
            min-height:0;
            &:after {content:none;}
        }

    }

    .topic-section {
        h2 {
            margin: 0 0 24px 0;
            font-size: 18px;
            line-height: 1.2;
            font-weight: 700;
            color: var(--color-white);
        }

        h3.count {
            margin: 0 0 16px;
            font-size: 12px;
            line-height: 18px;
            color: var(--color-white);

            strong {
                padding-right: 8px;
                font-family: 'TT Commons', sans-serif;
                font-size: 14px;
                line-height: 16px;
                font-weight: 700;
                letter-spacing: 0.24px;
                text-transform: uppercase;
            }
        }

        + .topic-section {
            margin-top: 40px;
            padding-top: 40px;
            border-top: 1px solid #495368;
        }

        .card-carousel + h3.count {
            margin-top: 32px;
        }
    }

    .video-buttons-group {
        margin: 0 0 16px;
        display: flex;
        gap: 9px;
        flex-direction: row;
        align-items: center;

        button,
        .complete-indicator {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            padding: 5px 16px;
            background-color: #13203B;
            color: var(--color-light-200);
            fill: var(--color-light-200);
            border: 1px solid #232F49;
            appearance: none;
            border-radius: 32px;
            font-size: 13px;
            line-height: 22px;

            svg {
                margin: 3px 0;
            }

            img {
                width: 16px;
                height: 16px;
            }
            .video-complete {display: none;}
            .bookmark-on {display: none;}
            &.completed {
                border:1px solid #66ABA4;
                .video-complete {display: block;}
                .video-not-complete {display:none;}
            }
        }

        button:hover {
            background-color: #232F49;
        }
        button[aria-pressed=true] {
            border:1px solid #B9CAFF;
            .bookmark-off {display: none;}
            .bookmark-on {display: block;}
        }

        .vote-group {
            display: flex;
            flex-direction: row;
            gap: 0;
            align-items: center;
        }

        .vote-up {
            padding-right: 8px;
            border-radius: 32px 0 0 32px;
            border-right: 0;
        }

        .vote-down {
            padding-left: 0;
            border-radius: 0 32px 32px 0;
            border-left: 0;
            position: relative;

            &::before {
                content: "";
                border-left: 1px solid var(--color-light-200);
                height: 16px;
            }
        }
    }

    .comments-abbreviated {
        margin: 0 0 24px;
        padding: 8px 16px;
        color: var(--color-white);
        background-color: #13203B;
        border: 1px solid #232F49;
        border-radius: 8px;

        h2 {
            margin: 0 0 4px;
            font-size: 14px;
            line-height: 21px;
        }

        .summary {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: flex-start;

            .author-avatar {
                margin: 0;
                padding: 0;
                width: 32px;
                height: 32px;
                border-radius: 16px;
                font-size: 12px;
                line-height: 32px;
                color: var(--color-white);
                background-color: #A85656;
                text-align: center;
                text-transform: uppercase;
            }

            p {
                margin: 0;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .tab-container.channels {
        .tab-list {
            display:flex;
            justify-content: center;
        }
    }
    .related-videos {
        margin: 0;
        padding: 0 16px 12px;
        list-style: none;

        li {
            margin: 0 0 16px;
            padding: 0;
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: center;
        }

        .poster {
            margin: 0;
            display: block;
            width: 150px;
            border-radius: 4px;
            position: relative;
            overflow: hidden;

            img.video-img {
                display: block;
                width: 100%;
                height: auto;
            }

            img.play-btn {
                position: absolute;
                width: 56px;
                height: 56px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .video-details {
            margin: 0;
            padding: 0;
        }

        .video-title {
            margin: 0 0 4px 0;
            padding: 0;
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
            letter-spacing: .24px;
            color: var(--color-white);

            // Line clamping
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .video-meta {
            margin: 0 0 12px;
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            gap: 1em;

            li {
                margin: 0;
                padding: 0;
                color: var(--color-text-muted);
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}
.congratulate-modal {
    text-align:center;
    h1 {
        font-size: 20px;
        line-height: 24px;
        font-weight:700;
        margin-top:20px;
        margin-bottom: 8px;
    }
    .badge {
        max-width:100px;
        margin: 10px auto;
        img {
            width:100%;
            max-width:100%;
        }
    }
    .button-row {
        display:flex;
        gap:12px;
        align-items: center;
        justify-content: center;
        margin-top: 1.5em;
        button {margin:0;}

        @media (max-width:767px) {
            flex-direction: column-reverse;
            margin-bottom:0.5em;
        }
    }
  }

.centered{
	position: absolute;
	top: calc(50% - 65px) ;
	left: 6%;
	color: white;
	font-size: 16px !important ;
}

.sub-heading {
	position: absolute;
	top: calc(50% - 45px) ;
	left: calc(50% - 40px);
	color: white;
	font-size: 16px !important ;
}
