.progress-bar-container {
    text-align:center;
    p {
        margin: 8px 0 0 0;
    }
}
.progress-bar {
    position: relative;
    height:16px;
    border-radius:var(--card-corner-default);
    background-color: var(--color-light-200);
    .bar {
        position: absolute;
        left:0;
        top:0;
        background: var(--color-gradient-teal);
        height:100%;
        border-radius:var(--card-corner-default);
        z-index: 1;
    }
}
.progress-ring {
    position: relative;
    // width:64px;
    // height:64px;

    .ring-icon {
        position: absolute;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
        color: var(--color-black);
        
        span {
            font-weight: 600;
            font-size: 48px;
            line-height: 52px;
            svg {
                vertical-align: middle;
                margin-bottom:4px;
            }
        }

        &.percentage1 {
            span {
                font-size:18px;
                color:#3B63B0;
            }
        }
        &.percentage2 {
            span {
                font-size:18px;
                color:#19A8E5
            }
        }
        &.percentage3 {
            span {
                font-size:18px;
                color:#8F36FE
            }
        }
        
    }
}

.top-card {

    .progress-ring {
        .ring-icon {
            color:var(--color-white);
        }
    }
}