.table-container {
    overflow-x:auto;
    caption {text-align:left;}
    .avatar {
        width:40px;
        height:40px;
        border-radius:50%;
        overflow:hidden;
    }
    .credential-list {
        list-style-type: none; margin:0;padding:0;
        display:flex; flex-wrap:wrap;
        gap:8px;
        li {
            flex:none;
        }
    }
    .skills-list, .language-list {
        margin:0;
        padding-left:20px;
    }
}
.table-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
    .pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        .page-number {
            margin-right:10px;
        }  
    }
    .select-all {
        display:flex;
        align-items: center;
        input {
            margin:0;
        }
    }
}
.table-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:10px;
}
.table-with-actions .table-container {
    max-height:300px;
    overflow:auto;
}
.sortable-table {
    width:100%;
    max-width:100%;
    border-collapse: collapse;
    font-size:1.125rem;

    thead {
        th {
            text-align:left;
            font-weight:normal;
            background-color: var(--color-light-200);
            button {
                padding: 10px 20px;
                white-space:nowrap;
                width:100%;
                text-align:left;
                svg {
                    vertical-align: bottom;
                    margin-left:5px;
                }
                &:hover {
                    background-color: #d2d7e2;
                    // color: var(--color-white);
                }
            }
        }
        
    }
    tbody {
        tr {
            cursor: pointer;
            &:hover {
                td,th {
                    // background-color: #6D01763D;
                    // background-color: #F4E8FA;
                    background-color:  #DDE3EE;
                }
            }
        }
        th, td {
            text-align:left;
            font-weight:normal;
            border-bottom:1px solid var(--color-border);
            padding: 24px 20px;
        }
        th {
            min-width:30%;
        }
        .avatar, .badge {
            max-width:40px;
            display: inline-block;
            flex:none;
            img {
                max-width: 100%;
                width: 100%;
            }
        }
        

        .employee-data {
            display: flex;
            align-items: center;
            gap:10px;
        }
        .employee-info {
            text-decoration: none;
            color:var(--color-black);

            span {
                display: block;
            }

            .employee-location {
                font-size:1rem;
                color: var(--color-text-light);
            }
            
            .employee-description {
                font-size:1rem;
                color: var(--color-text-light);
            }
        }
         .employee-name {
                // font-size: 1em;
             font-weight:600;
             &:hover {
                text-decoration:underline
            }
        }  
    }
}
.selectable-table {
    width:100%;
    max-width:100%;
    border-collapse: collapse;
    font-size:1.125rem;
    thead {
        th, td {
            text-align:left;
            font-weight:normal;
            background-color: var(--color-light-200);
            padding: 10px 20px;
        }
        
    }
    tbody {
        tr {
            cursor: pointer;
            &:hover {
                td,th {
                    // background-color: #6D01763D;
                    background-color:var(--color-border);
                }
            }
        }

        th, td {
            text-align:left;
            font-weight:normal;
            border-bottom:1px solid var(--color-border);
            padding: 24px 20px;
        }
        th {
            width:200px;
            min-width:200px;
            padding-left:0;

            @media (min-width:1025px) {
                width:40%;
            }
        }
        .checkbox-cell {
            width:54px;
            .checkbox {
                input {margin:0;}
            }
        }
        .action-cell {
            width: 80px;
            // padding-right:10px;
        }
        .employee-data {
            display: flex;
            align-items: center;
            gap:10px;
            .avatar {
                max-width:40px;
                display: inline-block;
                flex:none;
                img {
                    max-width: 100%;
                    width: 100%;
                }
            }
            .employee-info {
                text-decoration: none;
                color:var(--color-black);
                &:hover {
                    .employee-name {text-decoration:underline}
                }
            }
            span {
                display: block;
            }
            .employee-name {
                // font-size: 1em;
                font-weight:600;
            }
            .employee-description {
                font-size:1rem;
                color: var(--color-text-light);
            }
        }
    }
}

.color-plum {
    color: var(--color-plum);
}