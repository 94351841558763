.icon-14 {
    width:14px;
    height:14px;
    fill:currentColor;
}
.icon-16 {
    width:16px;
    height:16px;
    fill:currentColor;
}
.icon-18 {
    width:18px;
    height:18px;
    /* color: var(--color-text-light); */
    fill:currentColor;
}
.icon-24 {
    width:24px;
    height:24px;
    /* color: var(--color-text-light); */
    fill:currentColor;
}