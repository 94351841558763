h1, .text-h1 {
    font-family: 'TT Commons', sans-serif;
    font-size: 40px;
    line-height:40px;
    font-weight:700;
    letter-spacing: 0.4px;
    // color: var(--color-white);
    margin:0;
}

h2, .text-h2 {
    font-family: 'TT Commons', sans-serif;
    font-size: 28px;
    line-height:28px;
    font-weight:700;
    letter-spacing: 0.4px;
    // color: var(--color-black);
    margin:0;
}
h3, .text-h3 {
    font-family: 'TT Commons', sans-serif;
    font-size: 24px;
    line-height:28px;
    font-weight:400;
    letter-spacing: 0.4px;
    // color: var(--color-black);
    margin:0;
}
.text-lg {
    font-family: 'TT Commons', sans-serif;
    font-size: 20px;
    line-height:24px;
    font-weight:400;
}
p, .text-body {
    font-family: 'TT Commons', sans-serif;
    font-size: 18px;
    line-height:24px;
    font-weight:400;
    letter-spacing: 0.24px;
}
a {
    color: var(--color-teal);
    text-decoration:underline;
     &:hover {
          text-decoration:none;
      }
}
 .fa,
.fa-classic,
.fa-sharp,
.fas,
.fa-solid,
.far,
.fa-regular,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: "FontAwesome";
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }