.activity-list-container {

    .activity-list-search {
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap:10px;
        // width:100%;
        padding:10px;
        border-bottom:1px solid var(--color-border);

        .search {
            flex:1;
        }
    }

    .activity-list {
        list-style-type: none;
        margin:0;
        padding:0;

        .activity-item {
            display:flex;
            align-items: center;
            gap:10px;
            border-bottom:1px solid var(--color-border);
            padding:24px 0;
            margin: 0;
            @media (max-width: 559px){
                display: block;
            }

            &:last-of-type {
                border-bottom:0;
            }
            .activity-img {
                max-width:65px;
                img {width:100%;}
            }
            .activity-desc {
                flex:1;
                display:flex;
                align-items: center;
                gap:20px;
            }
            h3 {
                font-weight:600;font-size:1.125rem;
                > a {
                    color: var(--color-black);
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            p {margin:0;font-size:1.125rem;}
            button {
                position: relative;
                right: 4px;
                @media (max-width: 559px){
                    right: 0;
                    width: calc(100% - 12px);
                    margin-top: 15px;
                }
            }
        }
    }
}

.dashboard .activity-list-container {
    max-height: 548px;
    overflow-y: auto;
    overflow-x: hidden;
    clear: both;
    &.search-open {
        max-height: 565px;
        overflow-y: hidden;
        .activity-list {
            max-height: 500px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}

.activity-list-container .search.search-input {
    .gr-input {
        padding-left: 32px;
        border-radius: 100px;
    }
    .search-icon {
        right: unset;
        left: 10px;
        bottom: 46%;
    }
}
.filter {
    .button-unstyled {
        font-size: 18px;
        line-height: 34px;
        .icon-18 {
            margin-right: 5px;
            top: 3px;
            position: relative;
        }
    }
}