.pageWrapper {
    &.course {
        @media screen and (max-width: 1024px) {
            background: var(--color-dark-blue) !important;
            color: var(--color-white);
        
            &:before {
                display: none !important; 
            }            
        }

        .card {
            @media screen and (max-width: 1024px) {
                color: var(--color-black);
            }

            small {
                font-size: 20px;
                line-height: 1;
            }
        }

        h2 {
            font-weight: 700;
            font-size: 32px;
            line-height: 1;
            text-align: center;
            letter-spacing: 0.4px;
            & + div {
                margin-top: 40px;
            }
        }

        .course-summary {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.25;
            text-align: center;
            letter-spacing: 0.24px;
            transform: matrix(1, 0, -0.01, 1, 0, 0);
        }

        .course-nav {
            text-align: center;
            display: flex;
            margin: 50px 0;
            justify-content: center;

            @media screen and (max-width: 1024px) {                
                flex-direction: column;
                margin: 50px 0;
            }

            .button-primary:only-of-type {
                margin-left: 15px;

                @media screen and (max-width: 1024px) {   
                    margin:  0 0 20px 0;
                }
            }

            .button-primary:last-child {
                margin-left: 0;

                @media screen and (max-width: 1024px) {   
                    margin:  0;
                }
            }
        }

        h3 {
            font-weight: 700;
            font-size: 28px;
            line-height: 1;
            letter-spacing: 0.4px;

            @media screen and (max-width: 1024px) {
                color: #FFFFFF;
            }
        }

        h4 {
            font-weight: 700;
            font-size: 20px;
            line-height: 1.2;
            letter-spacing: 0.4px;
            
            @media screen and (max-width: 1024px) {
                color: #FFFFFF;
            }
        }

        p {
            font-weight: 500;
            font-size: 22px;
            line-height: 1.1;
            
            @media screen and (max-width: 1024px) {
                color: #d3d3d3;
            }
        }

        .alternate-options {
            text-align: center;

            button {
                @media screen and (max-width: 1024px) {
                    display: block;
                    width: 100%;
                }
            }
        }
        p.sub-text {
            font-size: 16px;
            text-align: center;
        }
    }
}

.back-btn {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.24px;
    color: #FEFEFE;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;    position: relative;
}

/* Locations -------*/
.locations-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .card {
        margin-bottom: 16px;

        h4 {
            font-weight: 700;
            font-size: 18px;
            line-height: 1.2;
            margin: 0 0 8px;

            @media screen and (max-width: 1024px) {
                color: var(--color-black);
            }
        } 

        .location-address {
            font-weight: 400;
            font-size: 18px;
            line-height: 1;
            letter-spacing: -1px;
            margin-bottom: 16px;
        }

        .location-info {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;    
            
            > div {
                display: flex;
                align-items: center;
                gap: 6px;
                color: #60646D;
                font-weight: 400;
                font-size: 14px;
            }

            .location-instuctor {
                grid-column: 1 / span 2;
            }

            .location-type {
                justify-content: flex-end;
            }
        }

        .icon-wrap {
            background-color: #E8EEFA;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            svg {
                fill: #696969;
            }
        }
    }
}
