.pageWrapper.careers {
    .tab-list-container {
        position:sticky;
        top:0;
        z-index: 11;
        // min-height:57px;
        
        &:after {
            content:'';
            position:absolute;
            top:0;
            bottom:0;
            width:calc(100% + 50px);
            left:-25px;
            background:var(--color-light-bg);
            z-index: -1;
        }

    }
    .pill .tab-list-container {
        top:86px;
        // padding:30px 0;
        &:after {
            top:-30px;
            height:calc(100% + 50px);
        }
    }
    
}

.jobLinks {
    display: flex;
    flex-wrap: wrap;

    &.dark-bg {
        margin: 0 -25px;
        padding: 10px;
    }

    .jobLink {
        width: calc(50% - 20px);
        background: #fff;
        color: #000;
        border-radius: 5px;
        padding: 10px;
        margin: 10px;
        p {
            margin: 0;
            font-size: 16px;
            line-height: 130%;
            a {
                font-weight: bold;
                text-decoration: none;
                margin-bottom: 5px;
                // color:var(--color-teal)!important;
                &:hover {
                    text-decoration: underline;
                }
            }
            &.description {
                font-size:14px;
                // color:var(--color-text-muted);
            }
        }
    }

}

.bestCompanies {
    h2 {
        margin-top: 25px;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            margin: 0;
            padding: 0;
            background: #fff;
            padding: 10px;
            margin-bottom: 20px;
            border-radius: 10px;
            box-shadow: 1px 2px 5px rgba(0,0,0,.2);
            
            img {
                width: 100%;
                height: auto;
                vertical-align: middle;
            }
            a {
                display: flex;
                align-items: center;
                color: #000;
                text-decoration: none;
                min-height:90px;
                .logo {
                    width: 34%;
                    max-width: 25%;
                   
                }
                .companyDetails {
                    padding-left: 20px;
                    // padding-top: 10px;
                    font-size: 18px;
                }
                
            }
            &:hover,
                &:active,
                &:focus {
                    // text-decoration: underline;
                    box-shadow: 1px 2px 10px rgba(0,0,0,.5);
                }
        }
    }

    &.byState ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        li {
            margin-bottom: 0;
            a svg {
                margin-right: 10px;
            }
        }
    }
}

.industryList {
    margin-top: 25px;
    ul {
        list-style: none;
        padding-left: 0;
        li {
            a {
                display: block;
                padding: 16px 20px;
                background: #fff;
                margin-bottom: 16px;
                border-radius: 10px;
                box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
                color: #000;
                text-decoration: none;
                &:hover,
                &:active,
                &:focus {
                    // text-decoration: underline;
                    box-shadow: 1px 2px 10px rgba(0,0,0,.5);
                }
            }

        }
    }
}

.jobPanel {
    .meta {
        display: flex;
        .results {
            flex-grow: 1;
        }
    }
}
.jobsListing {
    list-style: none;
    padding: 0;
    li {
        margin-bottom: 20px;
        a,
        a > span {
            display: block;
        }
        .company {
            display: flex;
            .logo {
                width: 20%;
                margin: 20px 20px 20px 0;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .name {
                line-height: 360%;
            }
        }
        .bookmark {
            position:absolute; 
            right:16px;
            top:16px;
            color:var(--color-text-light);
                z-index: 2;
            &[aria-pressed=true] {
                color:var(--color-plum);
            }
            &:hover {
                color:var(--color-plum);
            }
        }
        .title {
            font-weight: bold;
            font-size: 22px;
        }
        .meta {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #ccc;
            padding-top: 10px;
            margin-top: 10px;
            .posted {
                flex-grow: 1;
                text-align: right;
            }
        }
    }
}

.jobDetail {
    padding: 16px;
    .companyDetails {
        display: flex;
        .logo {
            width: 75px;
            margin-right: 20px;
            img {
                width: 100%;
                height: auto;
            }
        }
        .name {
            span {
                display: table-cell;
                vertical-align: middle;
                height: 70px;
            }
        }
    }

    .meta {
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .titleHolder {
        display: flex;
        margin-bottom: 25px;
        h3 {
            flex-grow: 1;
        }
    }
    .card-carousel .card-container {
        overflow-x: auto !important;
    }
}

.matchingSkills {
    margin-top: 25px;
    .list {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        li > a {
            display: block;
            line-height:24px;
        }
        .icon.teal {color:var(--color-teal);vertical-align: text-bottom;height:24px;display: inline-block;}
    }
}

.recruiter {
    display: flex;
    .avatar {
        margin-right: 20px;
    }
    .name {
        .actualName {
            font-size: 18px;
        }
    }
}

.pageWrapper.company {
    .logo {
        margin: 20px 0;
        img {
            width: 100%;
            height: auto;    
        }
    }
    .headerWithButton {
        display: flex;
        h1 {
            flex-grow: 1;
        }
    }

}

.postList,
.communityList {
    list-style: none;
    padding: 0;
    li {
        margin-bottom: 25px;
        .title {
            display: flex;
            gap: 10px;
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: bold;
        }
        .imageWrapper {
            width: 150px;
            min-width: 150px;
            height: 150px;
            overflow: hidden;
            border-radius: 10px;
            img {
                width: auto;
                height: 100%;
            }
        }
    }
}

.pageWrapper.recruiter {
    .picture {
        position: absolute;
        top: -66px;
        left: 0;
        overflow: hidden;
        z-index: 0;
        height: 380px;
        mask-image: linear-gradient(to bottom, rgb(0, 0, 0) 25%, transparent 100%);
        img {
            width: 100%;
            height: auto;
        }
    }
    h1 {
        margin-top: 270px;
        border-bottom: 1px solid var(--color-white);
        padding-bottom: 20px;
    }
    .affiliations {
        .title {
            font-size: 14px;
            font-weight: bold;
        }
        .company {
            height: 75px;
            img {
                height: 100%;
                width: auto;
            }
        }
    }
    section:not(.intro) {
        border-top: 1px solid var(--color-white);
    }
}