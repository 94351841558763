button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-weight:normal;
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }
  input,
textarea {
  font: inherit;
  letter-spacing: inherit;
  word-spacing: inherit; }
  .gr-input {
    display: block;
    margin: 4px 0;
    padding: 0 12px;
    height: 36px;
    color: var(--color-text-light);
    border: 1px solid var(--color-border);
    background: var(--color-white);
    border-radius: var(--input-corner);
    font-size: 14px;
    width: 100%;
    max-width: 100%; }
  
  .gr-input:focus {
    outline: 0;
    box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4; }
  
  .gr-input:disabled {
    color: var(--color-border);
    background-color: var(--color-light-grey); }
  
  .gr-input:read-only {
    border: 0; }
  
  .gr-input + .error-state, .gr-textarea + .error-state, .gr-checkbox + .error-state, .gr-radio + .error-state, .gr-select-container + .error-state, .gr-select-container + .error-state {
    display: none; }
  
  .gr-input.error, .gr-textarea.error, .gr-checkbox.error, .gr-radio.error, .gr-select-container.error, .gr-select-container.error {
    display: flex;
    align-items: center;
    border-color: #DB0020; }
    .gr-input.error + .error-state, .gr-textarea.error + .error-state, .gr-checkbox.error + .error-state, .gr-radio.error + .error-state, .gr-select-container.error + .error-state, .gr-select-container.error + .error-state {
      display: flex;
      align-items: center; }
      .gr-input.error + .error-state svg, .gr-textarea.error + .error-state svg, .gr-checkbox.error + .error-state svg, .gr-radio.error + .error-state svg, .gr-select-container.error + .error-state svg, .gr-select-container.error + .error-state svg {
        fill: #DB0020; }
      .gr-input.error + .error-state span, .gr-textarea.error + .error-state span, .gr-checkbox.error + .error-state span, .gr-radio.error + .error-state span, .gr-select-container.error + .error-state span, .gr-select-container.error + .error-state span {
        margin-left: 8px;
        color: #DB0020; }
  
  .gr-textarea {
    display: block;
    margin: 4px 0;
    padding: 12px;
    color: var(--color-text-light);
    border: 1px solid var(--color-border);
    background: var(--color-white);
    border-radius: var(--input-corner);
    font-size: 14px;
    width: 100%;
    max-width: 100%;
    min-height:80px;
    line-height: 1.5; }

  
  .gr-textarea:focus {
    outline: 0;
    box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4; }
  
  .gr-textarea:disabled {
    color: var(--color-border);
    background-color: #E9E9E9; }
  
  .gr-textarea:read-only {
    border: 0; }
  
  .gr-checkbox {
    margin-bottom: 14px;
    min-height: 16px;
    position: relative;
    display: flex;
    align-items: center; }
  
  .gr-checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    cursor: pointer; }
  
  .gr-checkbox input[type=checkbox] ~ label {
    display: inline-block;
    line-height: 1.5;
    min-height: 24px;
    padding-left: 2.5em;
    position: relative;
    z-index: 2;
    cursor: pointer;
    font-size: 14px; }
  
  .gr-checkbox input[type=checkbox] ~ span {
    height: 22px;
    line-height: 1.5;
    text-align: center;
    width: 22px;
    border: 2px solid var(--color-border);
    background: var(--color-white);
    border-radius: 2px;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 0; }
  
  .gr-checkbox input[type=checkbox]:disabled ~ span {
    height: 22px;
    line-height: 1.5;
    text-align: center;
    width: 22px;
    border: 2px solid var(--color-border);
    border-radius: 2px;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 0;
    background-color: #E9E9E9; }
  
  .gr-checkbox input[type=checkbox]:focus ~ span {
    outline: 0;
    box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4; }
  
  .gr-checkbox input[type=checkbox] ~ span svg {
    height: 24px;
    opacity: 0;
    width: 24px;
    top: -3px;
    position: relative;
    left: -3px;
    fill: #007FA3; }
  
  .gr-checkbox input[type=checkbox]:disabled ~ span svg {
    fill: var(--color-border); }
  
  .gr-checkbox input[type=checkbox]:checked ~ span svg {
    opacity: 1; }
  
  .gr-fieldset {
    border: none;
    padding: 0; }
  
  .gr-fieldset legend {
    margin-bottom: 8px; }
  
  .gr-radio {
    margin-bottom: 14px;
    min-height: 16px;
    position: relative;
    display: flex;
    align-items: center; }
  
  .gr-radio input[type=radio] {
    opacity: 0;
    position: absolute; }
  
  .gr-radio input[type=radio]:focus ~ span {
    outline: 0;
    box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4; }
  
  .gr-radio input[type=radio] + label {
    display: inline-block;
    line-height: 18px;
    padding-left: 28px;
    font-size: 14px;
    cursor: pointer; }
  
  .gr-radio input[type=radio] ~ span {
    -webkit-box-sizing: content-box;
    border: 2px solid var(--color-border);
    background: var(--color-white);
    border-radius: 50%;
    box-sizing: content-box;
    color: #6a7070;
    display: block;
    height: 5px;
    left: 0;
    padding: 3px 6px 6px 3px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 5px; }
  
  .gr-radio input[type=radio] ~ span svg {
    height: 18px;
    opacity: 0;
    width: 18px; }
  
  .gr-radio input[type=radio]:checked ~ span svg {
    opacity: 1;
    top: -5px;
    position: relative;
    left: -5px;
    fill: #007FA3; }
  
  .gr-radio input[type=radio]:disabled ~ span svg {
    opacity: 1;
    fill: var(--color-border);
    top: -5px;
    left: -5px;
    position: relative; }
  
  .gr-select-container {
    position: relative; }
  
  .gr-select-container svg {
    position: absolute;
    right: 12px;
    top: calc(50% - 9px);
    fill: var(--color-text-light); }
  
  .gr-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    margin: 4px 0;
    padding: 0 12px;
    padding-right: 42px;
    height: 36px;
    color: var(--color-text-light);
    border: 1px solid var(--color-border);
    background: var(--color-white);
    border-radius: var(--input-corner);
    font-size: 14px;
    width: 100%;
    max-width: 100%; }
  
  .gr-select:focus {
    outline: 0;
    box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4; }
  
  .gr-select:disabled {
    color: var(--color-border);
    background-color: #E9E9E9; }
  
  .gr-select:disabled + svg {
    fill: var(--color-border); }
  
  .gr-select[multiple] {
    height: auto; }
    .gr-select[multiple] option {
      cursor: pointer; }
  
      /* HED styles */

/* check-box-list.component.less */
.check-box-list label,
.checkbox label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-primary);
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}
.check-box-list label:not([class*="-inline"]) + label,
.checkbox label:not([class*="-inline"]) + label {
  margin-top: 1rem;
}
.check-box-list label[class*="-inline"],
.checkbox label[class*="-inline"] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0 0.625rem 0.625rem 0;
}
.check-box-list label[class*="-inline"]:only-of-type,
.checkbox label[class*="-inline"]:only-of-type,
.check-box-list label[class*="-inline"]:last-of-type,
.checkbox label[class*="-inline"]:last-of-type {
  margin-bottom: 0;
}
.small-controls.check-box-list label,
.small-controls.checkbox label {
  font-size: var(--typesize-minor);
}
.form-inverse .check-box-list label,
.form-inverse .checkbox label {
  color: var(--color--text-primary-inverse);
}
.check-box-list [type="checkbox"],
.checkbox [type="checkbox"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 1em;
  height: 1em;
  aspect-ratio: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: var(--color--field);
  outline-width: 0.125em;
  outline-style: solid;
  outline-color: var(--color--border-03);
  margin: 0 0.8125rem 0 0;
  position: relative;
  -webkit-transition: all 0.125s ease-in-out;
  -o-transition: all 0.125s ease-in-out;
  transition: all 0.125s ease-in-out;
  border-radius: 0.125em;
}
.check-box-list [type="checkbox"]::before,
.checkbox [type="checkbox"]::before {
  content: "";
  display: block;
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
  background-color: var(--color--ui-06);
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  margin: auto;
  position: absolute;
  z-index: -1;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  outline-color: transparent;
  outline-style: solid;
  outline-width: 0.1875em;
  outline-offset: -0.1875em;
}
.small-controls.check-box-list [type="checkbox"]::before,
.small-controls.checkbox [type="checkbox"]::before {
  width: 2em;
  height: 2em;
}
.form-inverse .check-box-list [type="checkbox"]::before,
.form-inverse .checkbox [type="checkbox"]::before {
  background-color: var(--color--ui-04);
}
.check-box-list [type="checkbox"]:hover:not([disabled]),
.checkbox [type="checkbox"]:hover:not([disabled]) {
  outline-color: var(--color--border-02);
}
.check-box-list [type="checkbox"]:hover:not([disabled])::before,
.checkbox [type="checkbox"]:hover:not([disabled])::before {
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
.check-box-list [type="checkbox"]:focus:not([disabled]),
.checkbox [type="checkbox"]:focus:not([disabled]) {
  outline-color: var(--color--border-02);
}
.check-box-list [type="checkbox"]:focus:not([disabled])::before,
.checkbox [type="checkbox"]:focus:not([disabled])::before {
  background-color: transparent;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  outline-color: var(--color--interactive-focus-field);
}
.small-controls.check-box-list [type="checkbox"],
.small-controls.checkbox [type="checkbox"] {
  width: 1rem;
  height: 1rem;
  outline-width: 0.125rem;
  outline-offset: -0.125rem;
}
.small-controls.check-box-list [type="checkbox"]:focus,
.small-controls.checkbox [type="checkbox"]:focus,
.small-controls.check-box-list [type="checkbox"]:focus-visible,
.small-controls.checkbox [type="checkbox"]:focus-visible {
  outline-offset: -0.125rem;
}
.check-box-list [type="checkbox"]:checked,
.checkbox [type="checkbox"]:checked {
  outline-color: var(--color--interactive-primary);
}
.check-box-list [type="checkbox"]:checked:hover,
.checkbox [type="checkbox"]:checked:hover {
  outline-color: var(--color--interactive-primary);
}
.check-box-list [type="checkbox"]:focus,
.checkbox [type="checkbox"]:focus,
.check-box-list [type="checkbox"]:focus-visible,
.checkbox [type="checkbox"]:focus-visible {
  outline-color: var(--color--interactive-focus-field);
  outline-offset: -0.0525em;
}
.check-box-list [type="checkbox"][required][aria-checked="false"][aria-invalid="false"],
.checkbox [type="checkbox"][required][aria-checked="false"][aria-invalid="false"],
.has-error.check-box-list [type="checkbox"],
.has-error.checkbox [type="checkbox"],
.has-error .check-box-list [type="checkbox"],
.has-error .checkbox [type="checkbox"] {
  outline-color: var(--color--alert-error);
}
.check-box-list [type="checkbox"]:focus,
.checkbox [type="checkbox"]:focus {
  outline-offset: unset;
}
.check-box-list [type="checkbox"]:checked:not([disabled]),
.checkbox [type="checkbox"]:checked:not([disabled]) {
  background: var(--color--interactive-primary);
}
.has-error.check-box-list [type="checkbox"]:checked:not([disabled]),
.has-error.checkbox [type="checkbox"]:checked:not([disabled]),
.has-error .check-box-list [type="checkbox"]:checked:not([disabled]),
.has-error .checkbox [type="checkbox"]:checked:not([disabled]) {
  background: var(--color--alert-error);
}
.form-inverse .has-error.check-box-list [type="checkbox"]:checked:not([disabled]),
.form-inverse .has-error.checkbox [type="checkbox"]:checked:not([disabled]),
.form-inverse .has-error .check-box-list [type="checkbox"]:checked:not([disabled]),
.form-inverse .has-error .checkbox [type="checkbox"]:checked:not([disabled]) {
  background: var(--color--alert-error-inverse);
}
.check-box-list [type="checkbox"]:checked:not([disabled]):focus,
.checkbox [type="checkbox"]:checked:not([disabled]):focus {
  outline-color: var(--color--interactive-primary);
}
.has-error.check-box-list [type="checkbox"]:checked:not([disabled]):focus,
.has-error.checkbox [type="checkbox"]:checked:not([disabled]):focus,
.has-error .check-box-list [type="checkbox"]:checked:not([disabled]):focus,
.has-error .checkbox [type="checkbox"]:checked:not([disabled]):focus {
  outline-color: var(--color--alert-error);
}
.form-inverse .has-error.check-box-list [type="checkbox"]:checked:not([disabled]):focus,
.form-inverse .has-error.checkbox [type="checkbox"]:checked:not([disabled]):focus,
.form-inverse .has-error .check-box-list [type="checkbox"]:checked:not([disabled]):focus,
.form-inverse .has-error .checkbox [type="checkbox"]:checked:not([disabled]):focus {
  outline-color: var(--color--alert-error-inverse);
}
.check-box-list [type="checkbox"]:checked::after,
.checkbox [type="checkbox"]:checked::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 14px;
  aspect-ratio: 1;
  -webkit-mask-image: url("../../assets/icons/sprite-controls.svg#icon-check");
  mask-image: url("../../assets/icons/sprite-controls.svg#icon-check");
  width: 0.875em;
  background: var(--color--field);
  margin: auto;
  position: absolute;
}
.check-box-list [type="checkbox"][disabled],
.checkbox [type="checkbox"][disabled] {
  color: var(--color--interactive-disabled-02);
  background-color: var(--color--ui-01);
  outline-color: var(--color--interactive-disabled-02);
  pointer-events: none;
}
.check-box-list [type="checkbox"][disabled]::-webkit-input-placeholder, .checkbox [type="checkbox"][disabled]::-webkit-input-placeholder {
  color: var(--color--interactive-disabled-02);
}
.check-box-list [type="checkbox"][disabled]::-moz-placeholder, .checkbox [type="checkbox"][disabled]::-moz-placeholder {
  color: var(--color--interactive-disabled-02);
}
.check-box-list [type="checkbox"][disabled]:-ms-input-placeholder, .checkbox [type="checkbox"][disabled]:-ms-input-placeholder {
  color: var(--color--interactive-disabled-02);
}
.check-box-list [type="checkbox"][disabled]::-ms-input-placeholder, .checkbox [type="checkbox"][disabled]::-ms-input-placeholder {
  color: var(--color--interactive-disabled-02);
}
.check-box-list [type="checkbox"][disabled]::placeholder,
.checkbox [type="checkbox"][disabled]::placeholder {
  color: var(--color--interactive-disabled-02);
}
.form-inverse .check-box-list [type="checkbox"][disabled],
.form-inverse .checkbox [type="checkbox"][disabled] {
  color: var(--color--interactive-disabled-01);
  background-color: var(--color--interactive-disabled-02);
}
.form-inverse .check-box-list [type="checkbox"][disabled]::-webkit-input-placeholder, .form-inverse .checkbox [type="checkbox"][disabled]::-webkit-input-placeholder {
  color: var(--color--interactive-disabled-01);
}
.form-inverse .check-box-list [type="checkbox"][disabled]::-moz-placeholder, .form-inverse .checkbox [type="checkbox"][disabled]::-moz-placeholder {
  color: var(--color--interactive-disabled-01);
}
.form-inverse .check-box-list [type="checkbox"][disabled]:-ms-input-placeholder, .form-inverse .checkbox [type="checkbox"][disabled]:-ms-input-placeholder {
  color: var(--color--interactive-disabled-01);
}
.form-inverse .check-box-list [type="checkbox"][disabled]::-ms-input-placeholder, .form-inverse .checkbox [type="checkbox"][disabled]::-ms-input-placeholder {
  color: var(--color--interactive-disabled-01);
}
.form-inverse .check-box-list [type="checkbox"][disabled]::placeholder,
.form-inverse .checkbox [type="checkbox"][disabled]::placeholder {
  color: var(--color--interactive-disabled-01);
}
.check-box-list [type="checkbox"][disabled]:checked,
.checkbox [type="checkbox"][disabled]:checked {
  outline-color: var(--color--ui-01);
}
.check-box-list [type="checkbox"][disabled]:checked::after,
.checkbox [type="checkbox"][disabled]:checked::after {
  background-color: var(--color--interactive-disabled-02);
}
.form-inverse .check-box-list [type="checkbox"][disabled],
.form-inverse .checkbox [type="checkbox"][disabled] {
  outline-color: var(--color--interactive-disabled-03);
}
.form-inverse .check-box-list [type="checkbox"][disabled]:checked,
.form-inverse .checkbox [type="checkbox"][disabled]:checked {
  outline-color: var(--color--interactive-disabled-02);
}
.form-inverse .check-box-list [type="checkbox"][disabled]:checked::after,
.form-inverse .checkbox [type="checkbox"][disabled]:checked::after {
  background: var(--color--interactive-disabled-03);
}
.small-controls.check-box-list [type="checkbox"]:checked::after,
.small-controls.checkbox [type="checkbox"]:checked::after {
  width: 0.75rem;
  height: 0.75rem;
}
@supports selector(:focus-visible) {
  .check-box-list [type="checkbox"],
  .checkbox [type="checkbox"] {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    outline-color: var(--color--border-03);
  }
  .check-box-list [type="checkbox"]:hover:not([disabled]),
  .checkbox [type="checkbox"]:hover:not([disabled]) {
    outline-color: var(--color--border-02);
  }
  .check-box-list [type="checkbox"]:focus:not([disabled]),
  .checkbox [type="checkbox"]:focus:not([disabled]) {
    outline-color: var(--color--border-02);
  }
  .check-box-list [type="checkbox"]:focus:not([disabled])::before,
  .checkbox [type="checkbox"]:focus:not([disabled])::before {
    border-style: solid;
    border-color: transparent;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .check-box-list [type="checkbox"]:checked:not([disabled]),
  .checkbox [type="checkbox"]:checked:not([disabled]) {
    outline-color: var(--color--interactive-primary);
  }
  .has-error.check-box-list [type="checkbox"]:checked:not([disabled]),
  .has-error.checkbox [type="checkbox"]:checked:not([disabled]) {
    outline-color: var(--color--alert-error);
  }
  .form-inverse .has-error.check-box-list [type="checkbox"]:checked:not([disabled]),
  .form-inverse .has-error.checkbox [type="checkbox"]:checked:not([disabled]) {
    outline-color: var(--color--alert-error-inverse);
  }
  .check-box-list [type="checkbox"]:checked:not([disabled]):focus,
  .checkbox [type="checkbox"]:checked:not([disabled]):focus {
    outline-color: var(--color--interactive-primary);
  }
  .has-error.check-box-list [type="checkbox"]:checked:not([disabled]):focus,
  .has-error.checkbox [type="checkbox"]:checked:not([disabled]):focus {
    outline-color: var(--color--alert-error);
  }
  .form-inverse .has-error.check-box-list [type="checkbox"]:checked:not([disabled]):focus,
  .form-inverse .has-error.checkbox [type="checkbox"]:checked:not([disabled]):focus {
    outline-color: var(--color--alert-error-inverse);
  }
  .check-box-list [type="checkbox"]:checked:not([disabled]):hover,
  .checkbox [type="checkbox"]:checked:not([disabled]):hover {
    outline-color: var(--color--interactive-primary);
  }
  .has-error.check-box-list [type="checkbox"]:checked:not([disabled]):hover,
  .has-error.checkbox [type="checkbox"]:checked:not([disabled]):hover,
  .has-error .check-box-list [type="checkbox"]:checked:not([disabled]):hover,
  .has-error .checkbox [type="checkbox"]:checked:not([disabled]):hover {
    outline-color: var(--color--alert-error);
  }
  .check-box-list [type="checkbox"][disabled],
  .checkbox [type="checkbox"][disabled] {
    outline-color: transparent;
    border-color: var(--color--interactive-disabled-02);
  }
  .form-inverse .check-box-list [type="checkbox"][disabled],
  .form-inverse .checkbox [type="checkbox"][disabled] {
    outline-color: transparent;
    border-color: var(--color--interactive-disabled-03);
  }
  .small-controls.check-box-list [type="checkbox"],
  .small-controls.checkbox [type="checkbox"] {
    width: 1rem;
    height: 1rem;
    outline: unset;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-width: 0.14285714em;
  }
  .small-controls.check-box-list [type="checkbox"]:focus,
  .small-controls.checkbox [type="checkbox"]:focus,
  .small-controls.check-box-list [type="checkbox"]:focus-visible,
  .small-controls.checkbox [type="checkbox"]:focus-visible {
    outline: unset;
  }
  .small-controls.check-box-list [type="checkbox"]:checked::after,
  .small-controls.checkbox [type="checkbox"]:checked::after {
    -webkit-transform: scale(1.15);
        -ms-transform: scale(1.15);
            transform: scale(1.15);
  }
  .check-box-list [type="checkbox"][required][aria-checked="false"][aria-invalid="false"],
  .checkbox [type="checkbox"][required][aria-checked="false"][aria-invalid="false"],
  .has-error.check-box-list [type="checkbox"],
  .has-error.checkbox [type="checkbox"],
  .has-error .check-box-list [type="checkbox"],
  .has-error .checkbox [type="checkbox"] {
    border-color: var(--color--alert-error);
  }
}

.filter-menu {
  position: relative;
  z-index: 3;
  button {
    border-radius:8px;
    padding: 4px 12px;
  }

  button[aria-expanded=true] {
    background-color: black;
    color:white;
   
  }

  .menu {
    position:absolute;
    z-index: 1111;
    right:0;
    top:calc(100% + 4px);
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    padding:24px;
    box-shadow: 1px 2px 5px rgba(0,0,0,.2);
    min-width:200px;

    .form-input {
      margin: 1em 0;
    }
  }
}