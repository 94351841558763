@mixin child {
    @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
            z-index: calc(10 - #{$i});
        }
    }
}

.avatar-list {
    position: relative;
    z-index: 0;
    .avatar {
        position: relative;
        display:inline-block;
        width:20px;
        height:20px;
        border-radius:50%;
        background-color: var(--color-light-200);
        background-size: cover;
        border: 2px solid var(--color-white);
        z-index: 10;
        + .avatar {
            margin-left: -10px;
            @include child
        }
    }
}

/*placeholder until design complete */
.users-label {
    display: flex;
    gap:8px;
    align-items: center;
    color: var(--color-text-light);
}