nav.employee-nav {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--color-dark-blue);

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        align-items: stretch;

        li {
            flex-basis: 25%;
            flex-grow: 1;
            
            a {
                margin: 0;
                padding: 7px 12px 39px;
                border: none;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 4px;
                align-items: center;
                font-size: 14px;
                line-height: 1.143;
                font-weight: 400;
                text-decoration: none;
                background-color: var(--color-dark-blue);
                color: var(--color-text-muted);

                &[aria-pressed='true'] {
                    color: var(--color-white);
                    font-weight: 700;
                }
            }
        }
    }
}