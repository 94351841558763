.toast {
    position: fixed;
    bottom:24px;
    width:calc(100% - var(--outside-space) - var(--outside-space));
    margin: var(--outside-space);
    background: #FEFEFE;
    border-radius: 12px;
    padding: 16px 24px;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:16px;
    visibility: hidden; /* Hidden by default. Visible on click */
    z-index: 1111;
    max-width:400px;

    &.position-top {
      top: 50px;
      bottom: auto;

      &.show {
        -webkit-animation: fadeintop 0.5s;
        animation: fadeintop 0.5s;    
      }
    }
    
    p {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin:0;
    }

    &.show {
        visibility: visible; /* Show the snackbar */
        /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
        However, delay the fade out process for 2.5 seconds */
        -webkit-animation: fadein 0.5s;
        animation: fadein 0.5s;      
    }

    &.animate-out {
        visibility: visible; /* Show the snackbar */
        -webkit-animation: fadeout 0.5s;
        animation: fadeout 0.5s;
    }

    .close {
      background-color: transparent;
      border: 0;
    }
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

  @-webkit-keyframes fadeintop {
    from {top: 0; opacity: 0;}
    to {top: 50px; opacity: 1;}
  }
  
  @keyframes fadeintop {
    from {top: 0; opacity: 0;}
    to {top: 40px; opacity: 1;}
  }