header {
    display:flex;
    align-items: center;
    justify-content: space-between;    

    @media (max-width: 1024px) {
        background-color: #05112A;
    }

    .actions {
        display: flex;
        align-items: center;
        gap:12px;
        .icon-btn {
            color:var(--color-white);
        }
    }

    .checkout {
        display: flex;
        align-items: center;
        gap: 12px;
        color: #fefefe;

        svg {
            fill: #fefefe;
        }
    }

    @media (min-width:1025px) {
        .actions {
            .icon-btn {
                color:var(--color-black);
            }
            .status.new {
                border-color: var(--color-light-bg);
            }
        }
    }
}