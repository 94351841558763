.breadcrumb-container {
    color: var(--color-success);
    font-size: 18px;
    font-weight: 400;
    i.fa {
        margin: 0 9px;
    }
    i.fa:first-child {
        margin-left: 0;
    }
    span > i.fa:first-child {
        margin-left: 9px;
    }
    a, button {
        background:transparent;
        border:none;
        color: var(--color-success);
        text-decoration: none;
        &:hover,
        &:focus,
        &:active {
            color: var(--color-teal);
            text-decoration: underline;
        }
    }
}